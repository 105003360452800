<template>
  <div class="container-fluid py-4">
    <div class="row mb-4 commitment">
      <div class="col-lg-5 col-md-5 mb-md-0 mb-4">
        <div class="card">
          <div class="card-header p-3">
            <div class="row">
              <div class="col-lg-6 col-7">
                <h6 v-if="page">{{ page.title }}</h6>
                <p class="text-sm mb-0" v-if="page" v-html="page.content"></p>
              </div>
            </div>
          </div>
          <div class="card-body pb-3 p-3 h-auto">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    Choix campagne d'irrigation
                    <br />
                    <b-form-select
                      id="per-page-select"
                      v-model="year"
                      :options="years"
                      value-field="year_ref"
                      text-field="year_ref"
                      @change="get_grazing_list"
                      size="sm"
                      style="
                        padding: 8px;
                        border-radius: 5px;
                        background: transparent;
                      "
                    >
                      <template #first>
                        <b-form-select-option :value="null" disabled>
                          -- Please select an option --
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                  </div>
                  <div class="col-md-12">
                    Choix du territoire de rattachement
                    <br />
                    <b-form-select
                      id="per-page-select"
                      v-model="attached_territory"
                      :options="attached_territories"
                      value-field="attached_territory"
                      text-field="attached_territory"
                      @change="get_grazing_list"
                      size="sm"
                      style="
                        padding: 8px;
                        border-radius: 5px;
                        background: transparent;
                      "
                    >
                      <template #first>
                        <b-form-select-option :value="null" disabled>
                          -- Please select an option --
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                  </div>
                  <div class="col-md-12">
                    Choix agriculteur
                    <br />
                    <b-form-select
                      id="per-page-select"
                      v-model="grazing"
                      :options="grazing_list"
                      value-field="grazing"
                      text-field="grazing"
                      @change="getCommitments"
                      size="sm"
                      style="
                        padding: 8px;
                        border-radius: 5px;
                        background: transparent;
                      "
                    >
                      <template #first>
                        <b-form-select-option :value="null" disabled>
                          -- Please select an option --
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                  </div>
                </div>
                <div class="row" v-if="commitments">
                  <div class="col-md-10">
                    Liste des engagements
                    <br />
                    <b-form-select
                      :select-size="10"
                      id="per-page-select"
                      v-model="selectedCommitmentId"
                      :options="commitments"
                      value-field="id"
                      text-field="commitment_type__name"
                      @change="changeCommitment"
                      size="sm"
                      style="
                        padding: 8px;
                        border-radius: 5px;
                        background: transparent;
                      "
                    >
                    </b-form-select>
                  </div>
                </div>
                <div class="row" v-if="selectedCommitment">
                  <div class="col-md-12 pt-2">
                    <div
                      style="border: 1px solid #000"
                      class="p-2"
                      v-if="selectedCommitment.commitment_type__description"
                      v-html="selectedCommitment.commitment_type__description"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-if="commitments.length > 0">
        <div class="col-lg-7 col-md-7">
          <div class="card h-100">
            <div class="card-header pb-0">
              <h6>Déclaration (Documents à partagés)</h6>
            </div>
            <div class="card-body p-3">
              <div class="col-md-12">
                <div
                  v-for="(commitment, index) in commitments"
                  v-bind:key="index"
                >
                  <table class="table table-bordered table-responsive">
                    <thead>
                      <tr>
                        <td colspan="2">
                          <h5>{{ commitment.commitment_type__name }}</h5>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <template>
                        <tr
                          v-for="(
                            parameter, parameter_index
                          ) in commitment.parameter"
                          :key="parameter_index"
                        >
                          <td>
                            <table>
                              <tr style="border: 0">
                                <td style="border: 0">{{ parameter.name }}</td>
                              </tr>
                              <tr></tr>
                              <tr
                                v-for="(
                                  parameter_value, parameter_value_index
                                ) in commitments[index].parameter[
                                  parameter_index
                                ].parameter_values"
                                :key="
                                  parameter_index + '_' + parameter_value_index
                                "
                              >
                                <td>
                                  <b-form-select
                                    v-if="parameter.type === 'plot'"
                                    id="per-page-select"
                                    v-model="
                                      commitments[index].parameter[
                                        parameter_index
                                      ].parameter_values[parameter_value_index]
                                        .park_code
                                    "
                                    :options="plots"
                                    value-field="id"
                                    text-field="label"
                                    size="sm plot-select"
                                  >
                                    <template #first>
                                      <b-form-select-option
                                        :value="null"
                                        disabled
                                      >
                                        -- Parcel --
                                      </b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <b-form-select
                                    disabled
                                    v-else
                                    :options="plots"
                                    value-field="id"
                                    text-field="label"
                                    size="sm plot-select opacity-0"
                                  >
                                    <template #first>
                                      <b-form-select-option
                                        :value="null"
                                        disabled
                                      >
                                        -- Parcel --
                                      </b-form-select-option>
                                    </template>
                                  </b-form-select>

                                  <input
                                    type="text"
                                    placeholder="Valeur initiale"
                                    class="input-width-80"
                                    v-model="
                                      commitments[index].parameter[
                                        parameter_index
                                      ].parameter_values[parameter_value_index]
                                        .initial_value
                                    "
                                  />
                                  <template
                                    v-if="
                                      getTypeValue(parameter.type_value)[0] ===
                                      'text'
                                    "
                                  >
                                    <input
                                      type="text"
                                      placeholder="Valeur à déclarer"
                                      v-model="parameter_value.current_value"
                                    />
                                  </template>
                                  <template
                                    v-else-if="
                                      getTypeValue(parameter.type_value)[0] ===
                                      'Numeric'
                                    "
                                  >
                                    <input
                                      type="number"
                                      placeholder="Valeur à déclarer"
                                      v-model="parameter_value.current_value"
                                      style="max-width: 110px"
                                    />
                                  </template>
                                  <template
                                    v-else-if="
                                      getTypeValue(parameter.type_value)[0] ===
                                      'integer'
                                    "
                                  >
                                    <input
                                      type="number"
                                      placeholder="Valeur à déclarer"
                                      v-model="parameter_value.current_value"
                                      style="max-width: 110px"
                                    />
                                  </template>
                                  <template
                                    v-else-if="
                                      getTypeValue(parameter.type_value)[0] ===
                                      'boolean'
                                    "
                                  >
                                    <input
                                      type="radio"
                                      id="yes"
                                      value="1"
                                      v-model="parameter_value.current_value"
                                    />&nbsp; Oui
                                    <input
                                      type="radio"
                                      id="no"
                                      value="0"
                                      v-model="parameter_value.current_value"
                                    />&nbsp; Non
                                  </template>
                                  <template
                                    v-else-if="
                                      getTypeValue(parameter.type_value)[0] ===
                                      'list'
                                    "
                                  >
                                    <input
                                      type="text"
                                      placeholder="Valeur à déclarer"
                                      v-model="parameter_value.current_value"
                                      style="max-width: 110px"
                                    />
                                  </template>
                                  <template
                                    v-else-if="
                                      getTypeValue(parameter.type_value)[0] ===
                                      'date'
                                    "
                                  >
                                    <b-form-datepicker
                                      :id="
                                        'start-date-' +
                                        index +
                                        '-' +
                                        parameter_index +
                                        '-' +
                                        parameter_value_index
                                      "
                                      placeholder="Début"
                                      v-model="parameter_value.current_value"
                                      :date-format-options="{
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                      }"
                                      locale="fr"
                                      class="start-date input-type-date"
                                    ></b-form-datepicker>
                                  </template>

                                  <input
                                    type="text"
                                    placeholder="Valeur objective"
                                    class="input-width-80"
                                    v-model="
                                      commitments[index].parameter[
                                        parameter_index
                                      ].parameter_values[parameter_value_index]
                                        .objective_value
                                    "
                                  />
                                </td>
                                <td>
                                  <b-form-select
                                    id="per-page-select"
                                    v-model="
                                      commitments[index].parameter[
                                        parameter_index
                                      ].parameter_values[parameter_value_index]
                                        .status
                                    "
                                    :options="status_list"
                                    value-field="key"
                                    text-field="name"
                                    size="sm"
                                    style="
                                      padding: 8px;
                                      border-radius: 5px;
                                      background: transparent;
                                    "
                                  >
                                  </b-form-select>
                                </td>
                                <td>
                                  <b-button-toolbar>
                                    <template>
                                      <b-button
                                        v-b-modal="
                                          'modal-' +
                                          commitments[index].parameter[
                                            parameter_index
                                          ].parameter_values[
                                            parameter_value_index
                                          ].id
                                        "
                                        title="Afficher le fichier"
                                      >
                                        <b-icon
                                          icon="eye"
                                          aria-hidden="true"
                                        ></b-icon>
                                      </b-button>
                                      <b-modal
                                        :id="
                                          'modal-' +
                                          commitments[index].parameter[
                                            parameter_index
                                          ].parameter_values[
                                            parameter_value_index
                                          ].id
                                        "
                                        :title="
                                          commitment.title +
                                          ' - ' +
                                          parameter.name
                                        "
                                        @show="
                                          getDocuments(
                                            commitments[index].parameter[
                                              parameter_index
                                            ].parameter_values[
                                              parameter_value_index
                                            ].id
                                          )
                                        "
                                        @ok="
                                          saveDocuments(
                                            index,
                                            parameter_value.id
                                          )
                                        "
                                        @close="closeDocumentModel"
                                        @cancel="closeDocumentModel"
                                        cancel-title="Annuler"
                                        cancel-title-html="Annuler"
                                      >
                                        <b-form-file
                                          @change="loadDocument"
                                          multiple
                                        ></b-form-file>
                                        <table class="table table-bordered">
                                          <tr
                                            v-for="(
                                              document, document_index
                                            ) in documents"
                                            v-bind:key="document_index"
                                          >
                                            <td>{{ document.name }}</td>
                                            <td>
                                              <a
                                                class="btn"
                                                @click="
                                                  showDocument(
                                                    index,
                                                    commitments[index]
                                                      .parameter[
                                                      parameter_index
                                                    ].parameter_values[
                                                      parameter_value_index
                                                    ].id,
                                                    document_index
                                                  )
                                                "
                                                ><b-icon
                                                  icon="eye"
                                                  aria-hidden="true"
                                                ></b-icon
                                              ></a>
                                            </td>
                                          </tr>
                                        </table>
                                      </b-modal>
                                    </template>
                                    <b-button
                                      title="télécharger"
                                      @click="
                                        downloadZip(
                                          commitments[index].parameter[
                                            parameter_index
                                          ].parameter_values[
                                            parameter_value_index
                                          ].id
                                        )
                                      "
                                    >
                                      <b-icon
                                        icon="download"
                                        aria-hidden="true"
                                      ></b-icon>
                                    </b-button>
                                    <b-button
                                      v-b-modal="
                                        'modal-comment-' +
                                        commitments[index].parameter[
                                          parameter_index
                                        ].parameter_values[
                                          parameter_value_index
                                        ].id
                                      "
                                      title="Commentaire"
                                    >
                                      <b-icon
                                        icon="chat-right-dots"
                                        aria-hidden="true"
                                      ></b-icon>
                                    </b-button>
                                    <b-modal
                                      :id="
                                        'modal-comment-' +
                                        commitments[index].parameter[
                                          parameter_index
                                        ].parameter_values[
                                          parameter_value_index
                                        ].id
                                      "
                                      :title="
                                        commitment.title +
                                        ' - ' +
                                        parameter.name
                                      "
                                      @close="closeDocumentModel"
                                      @cancel="closeDocumentModel"
                                      cancel-title="Annuler"
                                      cancel-title-html="Annuler"
                                    >
                                      <table class="table table-bordered">
                                        <tr>
                                          <td>
                                            <b-form-textarea
                                              id="textarea"
                                              v-model="
                                                commitments[index].parameter[
                                                  parameter_index
                                                ].parameter_values[
                                                  parameter_value_index
                                                ].comment
                                              "
                                              placeholder="Entrez un commentaire..."
                                              rows="3"
                                              max-rows="6"
                                            ></b-form-textarea>
                                          </td>
                                        </tr>
                                      </table>
                                    </b-modal>
                                  </b-button-toolbar>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                        <tr border="0">
                          <td align="right">
                            <input
                              type="button"
                              style="float: right"
                              value="Enregister"
                              class="btn bg-default text-white"
                              @click="save(index)"
                            />
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                  <br />
                </div>
                <b-button @click="exportDataCSV">
                  Exporter les données en format .csv
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="col-lg-7 col-md-7" v-if="year && grazing">
          <div class="card h-100">
            <div class="card-header pb-0">
              <h6>Aucun engagement trouvé pour l’année {{ year }}</h6>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import JSZip from "jszip";
import saveAs from "save-as";
import { CsvBuilder } from "filefy";

export default {
  components: {},
  data: () => ({
    pageSlug: "commitment-declaration",
    page: null,
    cultures: [],
    message: null,
    message_status: null,
    errors: {},
    commitments: [],
    selectedCommitmentId: null,
    selectedCommitment: null,
    documents: [],
    plots: [],
    years: [],
    year: null,
    attached_territories: [],
    attached_territory: null,
    grazing_list: [],
    grazing: null,
    status_list: [],
    uploadedFile: null,
  }),
  created() {
    document.title =
      "Déclaration des données de suivi et des justificatifs des engagements individuels";
  },
  mounted() {
    this.get_years();
    this.get_subcategories();
    this.getPageContent();
    this.get_status_list();
  },
  methods: {
    async get_years() {
      const response = await request.get("/plot/year/list", {
        params: {
          filter_default: true,
        },
      });
      if (response.status === 200) {
        this.years = response.data.data;
      } else {
      }
    },
    async get_subcategories() {
      const response = await request.get("/plot/attached-territory/list", {
        params: {
          filter_default: true,
        },
      });
      if (response.status === 200) {
        this.attached_territories = response.data.data;
      } else {
      }
    },
    async get_grazing_list() {
      if (this.years != null && this.attached_territory != null) {
        const response = await request.get("/plot/grazing/list", {
          params: {
            attached_territory: this.attached_territory,
          },
        });
        this.grazing = null;
        if (response.status === 200) {
          this.grazing_list = response.data.data;
        }
      }
    },
    async get_status_list() {
      const response = await request.get("/commitment/get-status", {});
      this.status = null;
      if (response.status === 200) {
        this.status_list = response.data.data;
      }
    },
    changeCommitment() {
      let self = this;
      this.commitments.forEach(function (commitment) {
        if (commitment.id === self.selectedCommitmentId) {
          self.selectedCommitment = commitment;
        }
      });
    },
    async getCommitments() {
      await this.getPlotList();
      const response = await request.get("/commitment/list", {
        params: {
          grazing: this.grazing,
          year_ref: this.year,
        },
      });

      this.commitments = response.data.data;
    },
    async getPlotList() {
      const response = await request.get("/commitment/plot/list", {
        params: {
          grazing: this.grazing,
        },
      });
      this.plots = response.data.data;
    },
    async save(index) {
      let response = await request.put("/commitment/update", {
        parameter_data: this.commitments[index].parameter,
        commitment_id: this.commitments[index].id,
      });
      await this.getCommitments();
    },
    async getDocuments(parameter_value_id) {
      this.errors = {};
      this.message = null;
      const response = await request.get("/commitment/document/list", {
        params: {
          id: parameter_value_id,
        },
      });

      this.documents = response.data.data;
    },
    async saveDocuments(index, parameter_value_id) {
      this.errors = {};
      this.message = null;

      await request.post("/commitment/document/add", {
        commitment_id: this.commitments[index].id,
        parameter_value_id: parameter_value_id,
        parameter_document: this.documents,
      });

      await this.getCommitments();
    },
    showDocument(index, parameter_value_id, document_index) {
      let document = this.documents[document_index];
      if (document["id"] !== undefined) {
        let commitment_id = this.commitments[index].id;

        let route = this.$router.resolve({
          path:
            "/commitment-document?commitment_id=" +
            commitment_id +
            "&parameter_value_id=" +
            parameter_value_id +
            "&document_id=" +
            document["id"],
        });
        window.open(route.href);
      } else {
        window.open(this.uploadedFile);
      }
    },
    async downloadZip(parameter_value_id) {
      await this.getDocuments(parameter_value_id);
      let new_zip = new JSZip();

      for (let i = 0; i < this.documents.length; i++) {
        let document = this.documents[i];
        const regex = /^data:(\w+)\/[a-z]+;base64,/;
        let content = document.content.replace(regex, "");

        new_zip.file(document.name, content, { base64: true });
      }

      new_zip.generateAsync({ type: "blob" }).then(function (content) {
        // see FileSaver.js
        saveAs(content, "documents.zip");
      });
    },
    async exportDataCSV() {
      let columns = [
        "Engagement",
        "Noms de paramètre",
        "Code du parc",
        "Valeur initiale",
        "Valeur actuelle",
        "Valeur objective",
        "Status",
        "Commentaire",
      ];

      let rows = [];

      this.commitments.forEach(function (commitment) {
        commitment.parameter.forEach(function (parameter) {
          let par = parameter;
          parameter.parameter_values.forEach(function (parameter_value) {
            rows.push([
              commitment.description,
              par.name,
              parameter_value.park_code,
              parameter_value.initial_value,
              parameter_value.current_value,
              parameter_value.objective_value,
              parameter_value.status,
              parameter_value.comment,
            ]);
          });
        });
      });

      new CsvBuilder("commitments.csv")
        .setColumns(columns)
        .addRows(rows)
        .exportFile();
    },
    async getPageContent() {
      const response = await request.get("/cms-page/" + this.pageSlug, {});
      if (response.status === 200) {
        this.page = response.data.data;
      } else {
        // Redirect 404
      }
    },
    loadDocument(e) {
      const self = this;
      let isValid = true;
      for (let i = 0; i < e.target.files.length; i++) {
        let file = e.target.files[i];
        const fileSize = file.size / 1024 / 1024; // in MiB

        if (fileSize <= 2) {
          let reader = new FileReader();
          reader.onload = (e) => {
            let file_base64 = e.target.result;
            self.documents.push({
              name: file.name,
              content: file_base64,
            });
          };
          reader.readAsDataURL(file);
        } else {
          // TODO replace this by message error in modal
          isValid = false;
          break;
        }
      }
      if (isValid) {
        this.uploadedFile = URL.createObjectURL(e.target.files[0]);
        e.target.value = null;
      }
    },
    async deleteDocument(index, parameter_value_id, document_index) {
      if (this.documents[document_index].id) {
        let response = await request.post("/commitment/document/delete", {
          commitment_id: this.commitments[index].id,
          parameter_value_id: parameter_value_id,
          document_id: this.documents[document_index].id,
        });

        if (response.data.success === true) {
          this.documents.splice(document_index, 1);
          await this.getCommitments();
        }
      } else {
        this.documents.splice(document_index, 1);
      }
    },
    closeDocumentModel() {
      this.documents = [];
    },
    getTypeValue(type_value) {
      return type_value.split(/[)(,.\s]/);
    },
  },
};
</script>
<style>
.input-type-date {
  display: inline-block !important;
  max-width: 110px;
}
.ol-attribution {
  display: none;
}
.btn-tab {
  margin-bottom: 0 !important;
  background-color: #757575 !important;
}

.form-control {
  border: 1px solid;
}
.opacity-0 {
  opacity: 0;
}
.plot-select {
  padding: 5px;
  margin-right: 5px;
  background: transparent;
  max-width: 190px;
}
.btn-toolbar .btn {
  margin-bottom: 0;
  margin-left: 5px;
}

.commitment p,
.commitment option {
  word-break: break-all;
  white-space: normal;
}

.input-width-80 {
  width: 100px;
  margin-right: 5px;
}
.table thead th {
  padding: 0;
}
.custom-file-label {
  display: none !important;
}
</style>
