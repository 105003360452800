import axiosInstance from "./api";
import localStorageManager from "../utils/localStorageManager";

const setup = () => {
  axiosInstance.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (
        originalConfig.url !== "/auth/login" &&
        originalConfig.url !== "auth/token/refresh" &&
        err.response
      ) {
        // Access Token was expired
        if (err.response.status === 401) {
          localStorageManager.removeItem("token");
          try {
            if (localStorageManager.getItem("refresh")) {
              const result = await axiosInstance.post("auth/token/refresh", {
                refresh: localStorageManager.getItem("refresh"),
              });

              if (result.status === 200) {
                localStorageManager.setItem("token", result.data.access);

                originalConfig.headers.Authorization =
                  "Bearer " + localStorageManager.getItem("token");

                return axiosInstance(originalConfig);
              }
            }
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }

      return Promise.reject(err);
    }
  );
};

export default setup;
