<template>
  <div class="embed-responsive embed-responsive-16by9 z-depth-1-half">
    <b-embed
      type="iframe"
      v-if="document"
      :src="document.content"
      width="100%"
      height="900"
      allowfullscreen
    ></b-embed>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  components: {},
  data() {
    return {
      document: {},
    };
  },
  created() {
    this.$emit("update:layout", "");
  },
  mounted() {
    this.getDocument();
  },
  methods: {
    async getDocument() {
      let commitment_id = this.$route.query.commitment_id;
      let parameter_value_id = this.$route.query.parameter_value_id;
      let document_id = this.$route.query.document_id;

      const response = await request.get("/commitment/get-document", {
        params: {
          commitment_id: commitment_id,
          parameter_value_id: parameter_value_id,
          document_id: document_id,
        },
      });
      if (response.status === 200) {
        this.document = response.data.data;
      } else {
        // Redirect 404
      }
    },
  },
};
</script>
