<template>
  <div
    ref="map-root"
    style="width: 100%; height: 100%; min-height: 520px"
  ></div>
</template>

<script>
import View from "ol/View";
import Map from "ol/Map";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import { Select } from "ol/interaction";
import { Fill, Stroke, Style } from "ol/style";
import "ol/ol.css";

export default {
  name: "MapComponent",
  components: {},
  props: {
    geoJson: Object,
  },
  data: () => ({
    olMap: null,
    vectorLayer: null,
    selectedFeature: null,
    oldFeature: null,
  }),
  mounted() {
    this.vectorLayer = new VectorLayer({
      source: new VectorSource({
        features: [],
      }),
    });

    this.olMap = new Map({
      target: this.$refs["map-root"],
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        this.vectorLayer,
      ],
      view: new View({
        zoomFactor: 2,
        zoom: 2,
        maxZoom: 20,
        center: [0, 0],
      }),
    });

    this.olMap.on("click", (event) => {
      const clicked = this.olMap.forEachFeatureAtPixel(
        event.pixel,
        (feature) => feature
      );
      if (clicked !== this.selectedFeature) {
        if (clicked !== undefined) {
          this.$set(this, "selectedFeature", clicked);
          this.setSelectedFeature(clicked, true);
        }
      }
    });

    this.updateSource(this.geoJson);
  },
  watch: {
    geoJson(value) {
      this.updateSource(value);
    },
    selectedFeature(value) {
      this.$emit("select", value);
    },
  },
  methods: {
    updateSource(geoJson) {
      if (geoJson) {
        const view = this.olMap.getView();
        const source = this.vectorLayer.getSource();

        const features = new GeoJSON({
          featureProjection: "EPSG:3857",
        }).readFeatures(geoJson);

        source.clear();
        source.addFeatures(features);
        view.fit(source.getExtent());
      }
    },
    // Add count through the interface
    setSelectedFeature(feature, callParent = false) {
      this.olMap.removeInteraction(this.olMap.getInteractions());

      const selectedStyle = new Style({
        fill: new Fill({
          color: "rgba(100,187,4,0.6)",
        }),
        stroke: new Stroke({
          color: "rgb(0,0,0)",
          width: 2,
        }),
      });

      // a normal select interaction to handle click
      const select = new Select({
        style: function (feature) {
          const color = "rgb(194,89,89)";
          selectedStyle.getFill().setColor(color);
          return selectedStyle;
        },
      });

      if (this.oldFeature !== null) {
        if (
          this.vectorLayer
            .getSource()
            .getFeatureById(this.oldFeature.getId()) !== null
        ) {
          this.vectorLayer
            .getSource()
            .getFeatureById(this.oldFeature.getId())
            .setStyle(null);
        }
      }

      this.oldFeature = feature;

      this.vectorLayer
        .getSource()
        .getFeatureById(feature.getId())
        .setStyle(selectedStyle);

      this.olMap.getView().fit(feature.getGeometry(), {
        color: "rgb(108,17,17)",
        padding: [170, 50, 30, 150],
        nearest: true,
        maxZoom: 17,
        duration: 1000,
      });

      let map = this.olMap;

      setTimeout(function () {
        // map.addInteraction(select);
      }, 500);
      if (callParent) {
        this.$parent.setSelectedFeature(feature);
      }
    },
    setSelectedFeatureById(id) {
      let feature = this.vectorLayer.getSource().getFeatureById(id);
      this.setSelectedFeature(feature);
    },
  },
};
</script>
