<template>
  <div class="container-fluid py-4">
    <div class="row mb-4">
      <div class="col-lg-12 col-md-12 mb-md-2 mb-4">
        <div class="row">
          <div
            v-for="report in reports"
            :key="report.id"
            class="col-xl-3 col-sm-6 mb-4"
          >
            <div class="card text-center" style="">
              <!--img class="card-img-top" src="#" alt="Card image cap"-->
              <div class="card-body">
                <h6 class="card-title">{{ report.title }}</h6>
                <a
                  v-bind:href="mediaUrl + '/' + report.file"
                  target="_blank"
                  class="btn bg-default text-white"
                  >Télécharger
                  <i class="material-icons opacity-10">file_download</i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "../../utils/request";

export default {
  name: "Profile",
  data() {
    return {
      reports: [],
      id: null,
      first_name: "",
      last_name: "",
      message: "",
      mediaUrl: null,
    };
  },
  created() {
    document.title = "Rapport à la CES";
  },
  mounted() {
    this.reports_list();
    this.mediaUrl = request.getMediaUrl();
  },
  methods: {
    async reports_list() {
      const response = await request.get("/report/list", {});
      if (response.status === 200) {
        this.reports = response.data.data;
      } else {
      }
    },
  },
};
</script>

<style scoped></style>
