<template>
  <div class="container-fluid py-4">
    <div class="row mb-4">
      <div class="col-lg-12 col-md-12 mb-md-0 mb-4">
        <div class="card">
          <div class="card-header p-3">
            <div class="row">
              <div class="col-lg-12">
                <h6 v-if="page">{{ page.title }}</h6>
                <p class="text-sm mb-0" v-if="page" v-html="page.content"></p>
              </div>
            </div>
          </div>
          <div class="card-body p3">
            <div class="row">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-md-12">
                    <span>Choisissez l'année :</span>
                  </div>
                  <div class="col-md-12">
                    <b-form-select
                      id="per-page-select"
                      v-model="year"
                      :options="years"
                      value-field="year_ref"
                      text-field="year_ref"
                      @change="getSummaryVolumesConsumed"
                      size="sm"
                      style="
                        padding: 8px;
                        border-radius: 5px;
                        background: transparent;
                      "
                    ></b-form-select>
                    <template v-if="items">
                      <hr />
                      <b-table
                        striped
                        hover
                        :fields="fields"
                        :items="items"
                      ></b-table>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  components: {},
  data() {
    return {
      year: null,
      years: [],
      fields: [
        {
          key: "attached_territory",
          label: "Sous bassin versant",
          filterByFormatted: true,
        },
        {
          key: "sum_autumn_consumer_in_focus",
          label: "Volumes consommés en période d'étiage (m³) ",
          filterByFormatted: true,
        },
        {
          key: "sum_spring_consume_in_focus",
          label: "Volumes consommés en période hivernale (m³)",
          filterByFormatted: true,
        },
      ],
      items: null,
      pageSlug: "summary-of-volumes-consumed",
      page: null,
    };
  },
  created() {
    document.title = "Synthèse des volumes consommés";
  },
  mounted() {
    this.get_years();
    this.getPageContent();
  },
  methods: {
    async get_years() {
      const response = await request.get("/plot/grazing/year/list", {});
      if (response.status === 200) {
        this.years = response.data.data;
      } else {
        this.years = {};
      }
    },
    async getPageContent() {
      const response = await request.get("/cms-page/" + this.pageSlug, {});
      if (response.status === 200) {
        this.page = response.data.data;
      } else {
        // Redirect 404
      }
    },
    async getSummaryVolumesConsumed() {
      if (this.year === null) {
        return;
      }

      const response = await request.get(
        "/plot/sampling-point/get-summary-volumes-consumed",
        {
          params: {
            year: this.year,
          },
        }
      );
      if (response.status === 200) {
        this.items = response.data.data;
      } else {
      }
    },
  },
};
</script>
