<template>
  <div class="container-fluid py-4" v-if="page" v-html="page.content"></div>
</template>

<script>
import request from "@/utils/request";
export default {
  components: {},
  data() {
    return {
      pageSlug: "home-page",
      page: null,
    };
  },
  created() {
    document.title = "Accueil";
  },
  mounted() {
    this.getPageContent();
  },
  methods: {
    async getPageContent() {
      const response = await request.get("/cms-page/" + this.pageSlug, {});
      if (response.status === 200) {
        this.page = response.data.data;
      } else {
        // Redirect 404
      }
    },
  },
};
</script>
