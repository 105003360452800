<template>
  <div class="container-fluid py-4">
    <template v-if="isLoading">
      <div class="row">
        <div
          class="col-lg-12 col-md-12"
          style="position: absolute; z-index: 99; height: 100%"
        >
          <div
            class="card"
            style="height: 100%; width: 99%; background: #bfbebe14"
          >
            <div
              class="d-flex justify-content-center align-middle"
              style="margin-top: auto; margin-bottom: auto"
            >
              <b-spinner class="align-middle" label="Loading..."></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div class="row mb-4">
      <div class="col-lg-8 col-md-8 mb-md-0 mb-4">
        <div class="card">
          <div class="card-header p-3">
            <div class="row">
              <div class="col-lg-12">
                <h6>{{ page.title }}</h6>
                <p class="text-sm mb-0" v-if="page" v-html="page.content"></p>
              </div>
            </div>
          </div>
          <div class="card-body pb-3 p-3 h-auto">
            <div class="row">
              <div class="col-md-12">
                <p>Visualisation des données</p>
              </div>
              <div class="col-md-12">
                <light-map-component
                  :geoJson="geoJson"
                  :data_set_type_id="data_set_type_id"
                  :remove_data_set_type_id="remove_data_set_type_id"
                  :push_in_data_set_type="push_in_data_set_type"
                  :clear_map="clear_map"
                ></light-map-component>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4">
        <div class="card h-100">
          <div class="card-header pb-0">
            <h6>Sélection des données à afficher</h6>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-md-12">
                <span>Choisissez l'année :</span>
              </div>
              <div class="col-md-12">
                <b-form-select
                  id="per-page-select"
                  v-model="year"
                  :options="years"
                  value-field="year_ref"
                  text-field="year_ref"
                  @change="getDirectorSchema"
                  size="sm"
                  style="
                    padding: 8px;
                    border-radius: 5px;
                    background: transparent;
                  "
                ></b-form-select>
              </div>
            </div>
            <template v-if="year">
              <hr />
              <div class="row">
                <div class="col-md-12">
                  <span>Choisissez les thématiques</span>
                </div>
                <div class="col-md-12">
                  <b-form-select
                    id="per-page-select"
                    v-model="dataset"
                    :options="datasets"
                    value-field="id"
                    text-field="name"
                    @change="get_dataset_types"
                    size="sm"
                    style="
                      padding: 8px;
                      border-radius: 5px;
                      background: transparent;
                    "
                  ></b-form-select>
                </div>
              </div>
              <hr />
            </template>
            <template v-if="dataset">
              <div class="row">
                <div class="col-md-12">
                  <b-form-group
                    label="Choix des données à afficher:"
                    v-slot="{ ariaDescribedby }"
                  >
                    <b-form-checkbox-group
                      :aria-describedby="ariaDescribedby"
                      name="some-radios"
                      :options="dataset_types"
                      value-field="id"
                      text-field="name"
                      @click.native="onClick"
                      @change="getDirectorSchema"
                      v-model="dataset_type"
                    ></b-form-checkbox-group>
                  </b-form-group>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  components: {},
  data: () => ({
    plots: [],
    geoJson: {
      type: "FeatureCollection",
      features: [],
    },
    data_set_type_id: null,
    remove_data_set_type_id: null,
    push_in_data_set_type: null,
    clear_map: false,
    perPage: 10,
    currentPage: 1,
    totalRows: 0,
    dataset: null,
    datasets: [],
    dataset_type: [],
    dataset_types: [],
    years: [],
    year: null,
    pageSlug: "biodiversity-master-plan",
    page: {
      title: null,
      content: null,
    },
    isLoading: false,
    data_set_type_selected: [],
  }),
  created() {
    document.title = "Schéma Directeur de la Biodiversité";
  },
  mounted() {
    this.get_years();
    this.get_datasets();
    this.getPageContent();
  },
  methods: {
    onClick(evt) {
      this.remove_data_set_type_id = null;
      if (evt.target.tagName === "INPUT") {
        if (!evt.target.checked) {
          this.remove_data_set_type_id = evt.target.value;
          let self = this;
          let filtered = this.data_set_type_selected.filter(function (value) {
            if (self.dataset != value["dataset"]) {
              return true;
            } else {
              return self.remove_data_set_type_id != value["data_set_type"];
            }
          });

          this.data_set_type_selected = filtered;
        }
      }
    },
    async get_years() {
      const response = await request.get("/director-schema/years", {
        params: {
          filter_default: true,
        },
      });

      if (response.status === 200) {
        this.years = response.data.data;
      } else {
      }
    },
    async get_datasets() {
      const response = await request.get("/director-schema/data-sets");
      if (response.status === 200) {
        this.datasets = response.data.data;
      } else {
      }
    },
    async get_dataset_types() {
      this.clear_map = false;
      const response = await request.get("/director-schema/data-set-types", {
        params: {
          dataset_id: this.dataset,
        },
      });

      if (response.status === 200) {
        this.dataset_types = response.data.data;
        this.dataset_type = [];

        if (this.data_set_type_selected.length != 0) {
          for (let i = 0; i < this.data_set_type_selected.length; i++) {
            let data_set_type_selected = this.data_set_type_selected[i];
            if (this.dataset == data_set_type_selected["dataset"]) {
              this.dataset_type.push(data_set_type_selected["data_set_type"]);
            }
          }
        }
      }
    },
    async getDirectorSchema() {
      if (
        this.year === null ||
        this.dataset === null ||
        this.dataset_type.length === 0 ||
        this.remove_data_set_type_id != null
      ) {
        return;
      }

      let limit = 100;
      this.isLoading = true;
      let data_set_type = this.dataset_type[this.dataset_type.length - 1];

      this.data_set_type_selected.push({
        dataset: this.dataset,
        data_set_type: data_set_type,
      });

      const response_count = await request.post("/director-schema/count", {
        year: this.year,
        data_set: this.dataset,
        data_set_type: data_set_type,
      });

      if (response_count.status === 200) {
        const count_item = parseInt(response_count.data.data.count);
        if (count_item < limit) {
          const response = await request.post("/director-schema/", {
            offset: 0,
            limit: limit,
            year: this.year,
            data_set: this.dataset,
            data_set_type: data_set_type,
          });

          if (response.status === 200) {
            this.geoJson = response.data.data;
            this.data_set_type_id = data_set_type;
            this.push_in_data_set_type = null;
          } else {
          }
        } else {
          let pages = Math.ceil(count_item / limit);
          for (let page = 0; page <= pages - 1; page++) {
            let offset = page * limit;
            let limit_item = limit + offset;

            const response = await request.post("/director-schema/", {
              offset: offset,
              limit: limit_item,
              year: this.year,
              data_set: this.dataset,
              data_set_type: data_set_type,
            });

            if (response.status === 200) {
              this.geoJson = response.data.data;
              this.data_set_type_id = data_set_type;
              if (page != 0) {
                this.push_in_data_set_type = true;
              } else {
                this.push_in_data_set_type = null;
              }
            } else {
            }
          }
        }
      }

      this.isLoading = false;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onRowSelected(items) {
      this.selectedItem = items[0];
      this.geoJson = this.selectedItem;
    },
    async getPageContent() {
      const response = await request.get("/cms-page/" + this.pageSlug, {});
      if (response.status === 200) {
        this.page = response.data.data;
      } else {
        // Redirect 404
      }
    },
  },
};
</script>
<style>
.ol-attribution {
  display: none;
}

.form-control {
  border: 1px solid;
}

.table thead th {
  padding: 0;
}
</style>
