<template>
  <aside
    class="
      sidenav
      navbar navbar-vertical navbar-expand-xs
      border-0 border-radius-right
      my-3
      fixed-start
    "
    id="sidenav-main"
  >
    <div class="sidenav-header">
      <i
        class="
          fas
          fa-times
          p-3
          cursor-pointer
          text-white
          opacity-5
          position-absolute
          end-0
          top-0
          d-xl-none
        "
        aria-hidden="true"
        id="iconSidenav"
      ></i>
      <a class="navbar-brand m-0" href="/">
        <span class="ms-1 font-weight-bold text-back">Accueil</span>
      </a>
    </div>
    <hr class="horizontal light mt-0 mb-2" />
    <div
      class="collapse navbar-collapse w-auto max-height-vh-100"
      id="sidenav-collapse-main"
    >
      <ul class="list-unstyled ps-0">
        <li class="menu parent-item p-3" v-if="role === 'supervisor'">
          <b-link
            class="btn-toggle align-items-center rounded collapsed"
            v-b-toggle="'supervisor'"
          >
            Superviseur
          </b-link>
        </li>
        <b-collapse id="supervisor" v-if="role === 'supervisor'">
          <ul class="btn-toggle-nav list-unstyled fw-normal small">
            <li class="child-item p-3">
              <router-link
                tag="a"
                :to="{ name: 'SupervisorIndex' }"
                class="link-dark rounded"
              >
                <span class="d-sm-inline">Accueil superviseur</span>
              </router-link>
            </li>
            <li class="child-item p-3">
              <router-link
                tag="a"
                :to="{ name: 'SupervisorControlParcelDeclaration' }"
                class="link-dark rounded"
              >
                <span class="d-sm-inline"
                  >Contrôle des déclarations à la parcelle</span
                >
              </router-link>
            </li>
            <li class="child-item p-3 last">
              <router-link
                tag="a"
                :to="{ name: 'SupervisorControlCommitmentDeclaration' }"
                class="link-dark rounded"
              >
                <span class="d-sm-inline">
                  Contrôle des données de suivi des engagements
                </span>
              </router-link>
            </li>
          </ul>
        </b-collapse>
        <li class="menu parent-item p-3" v-if="role === 'operator'">
          <b-link
            class="btn-toggle align-items-center rounded collapsed"
            v-b-toggle="'operator'"
          >
            Accès Agriculteur
          </b-link>
        </li>
        <b-collapse id="operator" v-if="role === 'operator'">
          <ul class="btn-toggle-nav list-unstyled fw-normal small">
            <li class="child-item p-3">
              <router-link
                tag="a"
                :to="{ name: 'OperatorIndex' }"
                class="link-dark rounded"
              >
                <span class="d-sm-inline">Présentation</span>
              </router-link>
            </li>
            <li class="child-item p-3">
              <router-link
                tag="a"
                :to="{ name: 'OperatorParcelDeclaration' }"
                class="link-dark rounded"
              >
                <span class="d-sm-inline">Déclaration à la parcelle</span>
              </router-link>
            </li>
            <li class="child-item p-3">
              <router-link
                tag="a"
                :to="{ name: 'CommitmentDeclaration' }"
                class="link-dark rounded"
              >
                <span class="d-sm-inline">
                  Déclaration relative aux engagements individuels
                </span>
              </router-link>
            </li>
          </ul>
        </b-collapse>
        <li class="menu parent-item p-3">
          <b-link
            class="btn-toggle align-items-center rounded collapsed"
            v-b-toggle="'home-collapse'"
            aria-expanded="true"
            >Accès public</b-link
          >
        </li>
        <b-collapse id="home-collapse" visible>
          <ul class="btn-toggle-nav list-unstyled fw-normal small">
            <li class="child-item p-3">
              <router-link
                tag="a"
                :to="{ name: 'PublicDashboard' }"
                class="link-dark rounded"
              >
                <span class="d-sm-inline">Tableau de bord public</span>
              </router-link>
            </li>
            <li class="child-item p-3">
              <router-link
                tag="a"
                :to="{ name: 'BiodiversityInventory' }"
                class="link-dark rounded"
              >
                <span class="d-sm-inline">Evolution de la biodiversité</span>
              </router-link>
            </li>
            <li class="child-item p-3">
              <router-link
                tag="a"
                :to="{ name: 'BiodiversityMasterPlan' }"
                class="link-dark rounded"
              >
                <span class="d-sm-inline"
                  >Schéma Directeur de la Biodiversité</span
                >
              </router-link>
            </li>
            <li class="child-item p-3">
              <router-link
                tag="a"
                :to="{ name: 'SummaryOfVolumesConsumed' }"
                class="link-dark rounded"
              >
                <span class="d-sm-inline"
                  >Synthèse des volumes d’irrigation consommés</span
                >
              </router-link>
            </li>
            <li class="child-item p-3 last">
              <router-link tag="a" to="/report/" class="link-dark rounded">
                <span class="d-sm-inline">Rapport annuel à la CES</span>
              </router-link>
            </li>
          </ul>
        </b-collapse>
      </ul>
    </div>
    <div class="sidenav-footer position-absolute w-100 bottom-0"></div>
  </aside>
</template>

<script>
import localStorageManager from "../../utils/localStorageManager";
import $ from "jquery";

export default {
  data() {
    return {
      role: "",
      headerBackground: require("@/assets/img/header-background.png"),
      logo1: require("@/assets/img/logos/1.png"),
      logo2: require("@/assets/img/logos/2.png"),
    };
  },
  created() {},
  mounted() {
    if (localStorageManager.getItem("role")) {
      this.role = localStorageManager.getItem("role");
    }
    const resizeObserver = new ResizeObserver((element) => {
      $("#sidenav-main").css({
        top: element[0].contentRect.height + 8 + "px",
      });
    });
    resizeObserver.observe(document.getElementById("top-navbar"));
  },
};
</script>
