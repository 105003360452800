<template>
  <div class="page-header align-items-start min-vh-75">
    <div class="container pt-10">
      <div class="row">
        <div class="col-lg-5 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-body">
              <h3 class="text-default font-weight-bolder text-center mt-2 mb-0">
                Mise à jour mot de passe
              </h3>

              <div
                v-bind:class="'alert alert-' + message_type"
                role="alert"
                v-if="message"
              >
                {{ message }}
              </div>
              <form role="form" class="text-start">
                <div class="input-group input-group-outline py-2 my-4">
                  <label class="form-label my-2"
                    ><img
                      class="form-label-icon"
                      :src="require(`@/assets/img/icons/password.png`)"
                  /></label>
                  <input
                    type="password"
                    class="form-control py-3"
                    placeholder="Mot de passe"
                    v-model="password"
                  />
                </div>
                <div class="input-group input-group-outline py-2 my-4">
                  <label class="form-label my-2"
                    ><img
                      class="form-label-icon"
                      :src="require(`@/assets/img/icons/password.png`)"
                  /></label>
                  <input
                    type="password"
                    class="form-control py-3"
                    placeholder="Confiramation mot de passe"
                    v-model="password_confirm"
                  />
                </div>
                <div class="text-center mt-3">
                  <button
                    type="button"
                    v-on:click="update_password"
                    class="btn bg-default text-white w-50 my-4 mb-2 py-3"
                  >
                    Changer
                  </button>
                </div>
                <p class="mt-4 text-sm text-center">
                  <router-link
                    tag="a"
                    to="/login"
                    class="text-primary text-info font-weight-normal"
                  >
                    Retourner vers la page login
                  </router-link>
                </p>
                <p class="mt-4 text-sm text-center">
                  <router-link
                    tag="a"
                    to="/reset-password"
                    class="text-primary text-info font-weight-normal"
                  >
                    Demander un nouveau mot de passe
                  </router-link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import request from "../../utils/request";
import localStorageManager from "../../utils/localStorageManager";

export default {
  name: "UpdatePassword",
  data() {
    return {
      password: "",
      password_confirm: "",
      message: "",
      message_type: "",
      errorMsg: `Une erreur s'est produite, veuillez réessayer`,
    };
  },
  methods: {
    async update_password() {
      if (this.password !== this.password_confirm) {
        this.message =
          "Les mots de passe ne sont pas égaux, veuillez vérifier votre mot de passe !";
      } else {
        let rp_token = this.$route.query.token;
        let email = this.$route.query.email;

        const response = await request.post(
          "/user/update-password",
          {
            email: email,
            rp_token: rp_token,
            password: this.password,
          },
          {},
          true
        );

        let data = response.data;
        this.message = data.message;
        this.message_type = data.success === true ? "success" : "warning";

        if (data.success === true) {
          let self = this;
          setTimeout(function () {
            self.$router.push("/login");
          }, 1000);
        }
      }
    },
    showHidePassword(e) {
      if (this.passwordType === this.typePassword) {
        this.passwordType = this.typeText;
      } else {
        this.passwordType = this.typePassword;
      }
      $(e.target).toggleClass("fa-eye-slash");
    },
  },
};
</script>

<style scoped></style>
