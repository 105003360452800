import DefaultLayout from "../components/Layout/DefaultLayout";
import NoSidebarLayout from "../components/Layout/NoSidebarLayout";
import EmptyLayout from "../components/Layout/EmptyLayout";

import Index from "../pages/Index";
import Login from "../pages/auth/Login";
import Logout from "../pages/auth/Logout";
import LoginAs from "../pages/auth/LoginAs";

import ResetPassword from "../pages/user/ResetPassword";
import UpdatePassword from "../pages/user/UpdatePassword";
import Profile from "@/pages/user/Profile";
import Report from "@/pages/report/Report";
import SupervisorIndex from "@/pages/supervisor/Index";
import SupervisorControlParcelDeclaration from "@/pages/supervisor/ControlParcelDeclaration";
import SupervisorControlCommitmentDeclaration from "@/pages/supervisor/ControlCommitmentDeclaration";
import OperatorIndex from "@/pages/operator/Index";
import OperatorParcelDeclaration from "@/pages/operator/ParcelDeclaration";
import OperatorCommitmentDeclaration from "@/pages/operator/CommitmentDeclaration";
import PublicDashboard from "@/pages/PublicDashboard";
import BiodiversityInventory from "@/pages/BiodiversityInventory";
import BiodiversityMasterPlan from "@/pages/BiodiversityMasterPlan";
import SummaryOfVolumesConsumed from "@/pages/SummaryOfVolumesConsumed";
import CommitmentDocument from "@/pages/operator/CommitmentDocument";

const routes = [
  {
    path: "/",
    component: DefaultLayout,
    children: [
      {
        path: "/",
        name: "Index",
        pageName: "home",
        component: Index,
      },
      {
        path: "/public-dashboard",
        name: "PublicDashboard",
        component: PublicDashboard,
      },
      {
        path: "biodiversity-inventory",
        name: "BiodiversityInventory",
        component: BiodiversityInventory,
      },
      {
        path: "biodiversity-master-plan",
        name: "BiodiversityMasterPlan",
        component: BiodiversityMasterPlan,
      },
      {
        path: "summary-of-volumes-consumed",
        name: "SummaryOfVolumesConsumed",
        component: SummaryOfVolumesConsumed,
      },
      {
        path: "report",
        name: "Report",
        component: Report,
      },
    ],
  },
  {
    path: "/supervisor/",
    component: DefaultLayout,
    children: [
      {
        path: "/",
        name: "SupervisorIndex",
        component: SupervisorIndex,
        meta: {
          requiresAuth: true,
          is_supervisor: true,
        },
      },
      {
        path: "/supervisor/control-parcel-declaration",
        name: "SupervisorControlParcelDeclaration",
        component: SupervisorControlParcelDeclaration,
        meta: {
          requiresAuth: true,
          is_supervisor: true,
        },
      },
      {
        path: "/supervisor/control-commitment-declaration",
        name: "SupervisorControlCommitmentDeclaration",
        component: SupervisorControlCommitmentDeclaration,
        meta: {
          requiresAuth: true,
          is_supervisor: true,
        },
      },
    ],
  },
  {
    path: "/operator/",
    component: DefaultLayout,
    children: [
      {
        path: "/",
        name: "OperatorIndex",
        component: OperatorIndex,
        meta: {
          requiresAuth: true,
          is_operator: true,
        },
      },
      {
        path: "/operator/parcel-declaration",
        name: "OperatorParcelDeclaration",
        component: OperatorParcelDeclaration,
        meta: {
          requiresAuth: true,
          is_operator: true,
        },
      },
      {
        path: "/operator/commitment-declaration",
        name: "CommitmentDeclaration",
        component: OperatorCommitmentDeclaration,
        meta: {
          requiresAuth: true,
          is_operator: true,
        },
      },
    ],
  },
  {
    path: "/",
    component: NoSidebarLayout,
    children: [
      {
        path: "login",
        name: "Login",
        component: Login,
        meta: {
          guest: true,
        },
      },
      {
        path: "login-as",
        name: "LoginAs",
        component: LoginAs,
        meta: {
          guest: true,
        },
      },
      {
        path: "logout",
        name: "Logout",
        component: Logout,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "profile",
        name: "Profile",
        component: Profile,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "reset-password",
        name: "ResetPassword",
        component: ResetPassword,
        meta: {
          guest: true,
        },
      },
      {
        path: "update-password",
        name: "UpdatePassword",
        component: UpdatePassword,
        meta: {
          guest: true,
        },
      },
    ],
  },
  {
    path: "/",
    component: EmptyLayout,
    children: [
      {
        path: "/commitment-document",
        name: "CommitmentDocument",
        component: CommitmentDocument,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];

export default routes;
