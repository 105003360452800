import HeaderComponent from "@/components/extra/HeaderComponent";
import SideBarComponent from "@/components/extra/SideBarComponent";
import FooterComponent from "@/components/extra/FooterComponent";
import LightMapComponent from "@/components/map/LightMapComponent";
import MapComponent from "@/components/map/MapComponent";
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component("header-component", HeaderComponent);
    Vue.component("side-bar-component", SideBarComponent);
    Vue.component("footer-component", FooterComponent);
    Vue.component("map-component", MapComponent);
    Vue.component("light-map-component", LightMapComponent);
  },
};

export default GlobalComponents;
