<template>
  <div class="container-fluid py-4">
    <div class="row mb-4">
      <div class="col-md-12 mb-md-0 mb-4">
        <div class="card">
          <div class="card-header p-3">
            <div class="row">
              <div class="col-lg-12">
                <h6>{{ page.title }}</h6>
                <p class="text-sm mb-0" v-if="page" v-html="page.content"></p>
              </div>
            </div>
          </div>
          <div class="card-body pb-3 p-3 h-auto">
            <div class="row">
              <div class="col-md-12">
                <p>Visualisation des données</p>
              </div>
              <div class="col-md-12">
                <map-component :geoJson="geoJson"></map-component>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  components: {},
  data: () => ({
    plots: [],
    geoJson: {},
    perPage: 10,
    currentPage: 1,
    totalRows: 0,
    perPageOptions: [10, 20, 30, 50],
    fields: [
      { key: "id", label: "ID", filterByFormatted: true },
      { key: "properties.grazing", label: "Grazing", filterByFormatted: true },
      {
        key: "properties.park_code",
        label: "Park Code",
        filterByFormatted: true,
      },
    ],
    query: null,
    selectedItem: null,
    selectMode: "single",
    pageSlug: "zero-point-inventory",
    page: null,
  }),
  created() {
    document.title = "Inventaire Point Zéro";
  },
  mounted() {
    this.getPageContent();
  },
  methods: {
    async getPageContent() {
      const response = await request.get("/cms-page/" + this.pageSlug, {});
      if (response.status === 200) {
        this.page = response.data.data;
      } else {
        // Redirect 404
      }
    },
  },
};
</script>
<style>
.ol-attribution {
  display: none;
}

.form-control {
  border: 1px solid;
}

.table thead th {
  padding: 0;
}
</style>
