import axios from "axios";

const API_DEV = "http://127.0.0.1/api";
const API_PROD = "https://api.observatoire-eau1779.fr/api";
const API_PREPROD = "http://api.epmp.studiolab.fr/api";
let API_URL = process.env.NODE_ENV === "production" ? API_PROD : API_DEV;

if (process.env.NODE_ENV === "preprod") {
  API_URL = API_PREPROD;
}

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;
