<template>
  <div class="container-fluid py-4">
    <div class="row mb-4 commitment">
      <div class="col-lg-5 col-md-5 mb-md-0 mb-4">
        <div class="card">
          <div class="card-header p-3">
            <div class="row">
              <div class="col-lg-6 col-7">
                <h6 v-if="page">{{ page.title }}</h6>
                <p class="text-sm mb-0" v-if="page" v-html="page.content"></p>
              </div>
            </div>
          </div>
          <div class="card-body pb-3 p-3 h-auto">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    Liste des engagements
                    <br />
                    <b-form-select
                      :select-size="10"
                      class="commitment"
                      id="per-page-select"
                      v-model="selectedCommitmentId"
                      :options="commitments"
                      value-field="id"
                      text-field="commitment_type__name"
                      @change="changeCommitment"
                      size="sm"
                      style="
                        padding: 8px;
                        border-radius: 5px;
                        background: transparent;
                      "
                    >
                    </b-form-select>
                  </div>
                </div>
                <div class="row" v-if="selectedCommitment">
                  <div class="col-md-12 pt-2">
                    <div style="border: 1px solid #000" class="p-2">
                      <p
                        class="text-sm mb-0"
                        v-if="selectedCommitment.commitment_type__description"
                        v-html="selectedCommitment.commitment_type__description"
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-7 col-md-7">
        <div class="card h-100">
          <div class="card-header pb-0">
            <div>
              <h6>Déclaration (Documents à partagés)</h6>
              <input
                type="button"
                style=""
                value="Confirmer la déclaration des données de suivi"
                class="btn bg-default text-white"
                @click="declarationConfirm"
              />
            </div>
          </div>
          <div class="card-body p-3">
            <div class="col-md-12">
              <div
                v-for="(commitment, index) in commitments"
                v-bind:key="index"
              >
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <td>
                        <h5>{{ commitment.commitment_type__name }}</h5>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(
                        parameter, parameter_index
                      ) in commitment.parameter"
                      v-bind:key="parameter_index"
                    >
                      <td>
                        <table>
                          <tr>
                            <td colspan="3">
                              {{ parameter.name }}
                            </td>
                          </tr>
                          <tr
                            v-for="(
                              parameter_value, parameter_value_index
                            ) in commitments[index].parameter[parameter_index]
                              .parameter_values"
                            v-bind:key="parameter_value_index"
                          >
                            <td class="pb-2">
                              <input
                                v-if="parameter.type === 'plot'"
                                type="button"
                                style=""
                                value="x"
                                class="bg-default text-white"
                                @click="
                                  deleteParameterValue(
                                    index,
                                    parameter_index,
                                    parameter_value_index
                                  )
                                "
                              />&nbsp;
                              <b-form-select
                                v-if="parameter.type === 'plot'"
                                id="per-page-select"
                                v-model="parameter_value.park_code"
                                :options="
                                  getFilteredPlots(
                                    index,
                                    parameter_index,
                                    parameter_value_index
                                  )
                                "
                                value-field="id"
                                text-field="label"
                                size="sm"
                                style="
                                  padding: 5px;
                                  margin-right: 5px;
                                  background: transparent;
                                  max-width: 190px;
                                "
                              >
                                <template #first>
                                  <b-form-select-option :value="null" disabled>
                                    -- Parcel --
                                  </b-form-select-option>
                                </template>
                              </b-form-select>
                              <template
                                v-if="
                                  getTypeValue(parameter.type_value)[0] ===
                                  'text'
                                "
                              >
                                <input
                                  type="text"
                                  placeholder="Valeur à déclarer"
                                  v-model="parameter_value.current_value"
                                />
                              </template>
                              <template
                                v-else-if="
                                  getTypeValue(parameter.type_value)[0] ===
                                  'Numeric'
                                "
                              >
                                <input
                                  type="number"
                                  placeholder="Valeur à déclarer"
                                  v-model="parameter_value.current_value"
                                />
                              </template>
                              <template
                                v-else-if="
                                  getTypeValue(parameter.type_value)[0] ===
                                  'integer'
                                "
                              >
                                <input
                                  type="number"
                                  placeholder="Valeur à déclarer"
                                  v-model="parameter_value.current_value"
                                />
                              </template>
                              <template
                                v-else-if="
                                  getTypeValue(parameter.type_value)[0] ===
                                  'boolean'
                                "
                              >
                                <input
                                  type="radio"
                                  id="yes"
                                  value="1"
                                  v-model="parameter_value.current_value"
                                />&nbsp; Oui
                                <input
                                  type="radio"
                                  id="no"
                                  value="0"
                                  v-model="parameter_value.current_value"
                                />&nbsp; Non
                              </template>
                              <template
                                v-else-if="
                                  getTypeValue(parameter.type_value)[0] ===
                                  'list'
                                "
                              >
                                <input
                                  type="text"
                                  placeholder="Valeur à déclarer"
                                  v-model="parameter_value.current_value"
                                />
                              </template>
                              <template
                                v-else-if="
                                  getTypeValue(parameter.type_value)[0] ===
                                  'date'
                                "
                              >
                                <b-form-datepicker
                                  :id="
                                    'start-date-' +
                                    index +
                                    '-' +
                                    parameter_index +
                                    '-' +
                                    parameter_value_index
                                  "
                                  placeholder="Début"
                                  v-model="parameter_value.current_value"
                                  :date-format-options="{
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                  }"
                                  locale="fr"
                                  class="start-date input-type-date"
                                ></b-form-datepicker>
                              </template>
                            </td>
                            <td class="pb-2">
                              <template v-if="parameter_value.id">
                                <b-button
                                  v-b-modal="'modal-' + parameter_value.id"
                                  class="no-margin-b"
                                >
                                  Importer les documents
                                </b-button>
                                <b-modal
                                  :id="'modal-' + parameter_value.id"
                                  :title="
                                    commitment.title + ' - ' + parameter.name
                                  "
                                  @show="getDocuments(parameter_value.id)"
                                  @ok="saveDocuments(index, parameter_value.id)"
                                  @close="closeDocumentModel"
                                  @cancel="closeDocumentModel"
                                  cancel-title="Annuler"
                                  cancel-title-html="Annuler"
                                >
                                  <b-form-file
                                    @change="loadDocument"
                                    multiple
                                  ></b-form-file>
                                  <table class="table table-bordered">
                                    <tr
                                      v-for="(
                                        document, document_index
                                      ) in documents"
                                      v-bind:key="document_index"
                                    >
                                      <td>{{ document.name }}</td>
                                      <td>
                                        <a
                                          class="btn"
                                          @click="
                                            showDocument(
                                              index,
                                              parameter_value.id,
                                              document_index
                                            )
                                          "
                                        >
                                          <b-icon
                                            icon="eye"
                                            aria-hidden="true"
                                          ></b-icon>
                                        </a>
                                      </td>
                                      <td>
                                        <a
                                          class="btn"
                                          @click="
                                            deleteDocument(
                                              index,
                                              parameter_value.id,
                                              document_index
                                            )
                                          "
                                        >
                                          <b-icon
                                            icon="trash-fill"
                                            aria-hidden="true"
                                          ></b-icon>
                                        </a>
                                      </td>
                                    </tr>
                                  </table>
                                </b-modal>
                              </template>
                              <template v-else>
                                <p style="width: 100%">
                                  Veuillez enregistrer la valeur du paramètre
                                  pour télécharger les documents
                                </p>
                              </template>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                v-if="parameter.type === 'plot'"
                                type="button"
                                style=""
                                value="Ajouter une déclaration"
                                class="btn bg-default text-white"
                                @click="
                                  addParameterValue(index, parameter_index)
                                "
                              />
                              &nbsp;&nbsp;&nbsp;
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div style="">
                  <input
                    type="button"
                    style="float: right"
                    value="Enregister"
                    class="btn bg-default text-white"
                    @click="save(index)"
                  />
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template>
      <Transition name="modal">
        <div v-if="show" class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">
              <div class="modal-body">
                <slot name="body">Engagement a été bien enregistrée</slot>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </template>
    <template>
      <Transition name="modal">
        <div v-if="showConfirm1" class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">
              <div class="modal-body">
                <slot name="body"
                  >Etes-vous sûr de vouloir enregistrer les modifications ?
                </slot>
              </div>
              <div class="modal-footer">
                <slot name="footer">
                  <button class="modal-default-button" @click="closeConfirm1">
                    X
                  </button>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </template>
    <template>
      <Transition name="modal">
        <div v-if="showConfirm2" class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">
              <div class="modal-body">
                <slot name="body"
                  >Vous attestez sur l'honneur que l'ensemble des informations
                  renseignées ici sont justes et veridiques
                </slot>
              </div>
              <div class="modal-footer">
                <slot name="footer">
                  <button class="modal-default-button" @click="confirm">
                    confirmer
                  </button>

                  <button class="modal-default-button" @click="cancel">
                    Annuler
                  </button>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </template>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  components: {},
  data: () => ({
    pageSlug: "commitment-declaration",
    page: null,
    cultures: [],
    message: null,
    message_status: null,
    errors: {},
    commitments: null,
    selectedCommitmentId: null,
    selectedCommitment: null,
    documents: [],
    plots: [],
    uploadedFile: null,
    show: false,
    showConfirm1: false,
    showConfirm2: false,
  }),
  created() {
    document.title =
      "Déclaration des données de suivi et des justificatifs des engagements individuels";
  },
  mounted() {
    this.getPageContent();
    this.getCommitments();
    this.getPlotList();
  },
  methods: {
    changeCommitment() {
      let self = this;
      this.commitments.forEach(function (commitment) {
        if (commitment.id === self.selectedCommitmentId) {
          self.selectedCommitment = commitment;
        }
      });
    },
    async getCommitments() {
      const response = await request.get("/commitment/list");
      this.commitments = response.data.data;
    },
    async getPlotList() {
      const response = await request.get("/commitment/plot/list");
      this.plots = response.data.data;
    },
    getFilteredPlots(commitment_index, parameter_index, parameter_value_index) {
      let commitment = this.commitments[commitment_index];
      let parameter = commitment.parameter[parameter_index];
      let parameter_values = parameter.parameter_values;
      let current_value = parameter.parameter_values[parameter_value_index];
      let plots = JSON.parse(JSON.stringify(this.plots));

      this.plots.forEach(function (plot) {
        parameter_values.forEach(function (value) {
          if (
            parseInt(value.park_code) === parseInt(plot.id) &&
            parseInt(plot.id) !== parseInt(current_value.park_code)
          ) {
            plots.forEach(function (tmp_plot, plot_index) {
              if (
                parseInt(value.park_code) === parseInt(tmp_plot.id) &&
                parseInt(tmp_plot.id) !== parseInt(current_value.park_code)
              ) {
                plots.splice(plot_index, 1);
              }
            });
          }
        });
      });

      return plots;
    },
    async save(index) {
      this.errors = {};
      this.message = null;
      const response = await request.post("/commitment/add", {
        parameter_data: this.commitments[index].parameter,
        commitment_id: this.commitments[index].id,
      });
      if (response.data.success === true) {
        await this.getCommitments();
        this.show = true;
        self = this;
        setTimeout(function () {
          self.show = false;
        }, 1500);
      }
    },
    addParameterValue(commitment_index, parameter_index) {
      this.commitments[commitment_index].parameter[
        parameter_index
      ].parameter_values.push({
        current_value: 0,
      });
    },
    deleteParameterValue(
      commitment_index,
      parameter_index,
      parameter_value_index
    ) {
      if (
        this.commitments[commitment_index].parameter[parameter_index]
          .parameter_values.length > 1
      ) {
        this.commitments[commitment_index].parameter[
          parameter_index
        ].parameter_values.splice(parameter_value_index, 1);
      }
    },
    async getDocuments(parameter_value_id) {
      this.errors = {};
      this.message = null;
      const response = await request.get("/commitment/document/list", {
        params: {
          id: parameter_value_id,
        },
      });

      this.documents = response.data.data;
    },
    async saveDocuments(index, parameter_value_id) {
      this.errors = {};
      this.message = null;

      await request.post("/commitment/document/add", {
        commitment_id: this.commitments[index].id,
        parameter_value_id: parameter_value_id,
        parameter_document: this.documents,
      });

      await this.getCommitments();
    },
    async getPageContent() {
      const response = await request.get("/cms-page/" + this.pageSlug, {});
      if (response.status === 200) {
        this.page = response.data.data;
      } else {
        // Redirect 404
      }
    },
    loadDocument(e) {
      const self = this;
      for (let i = 0; i < e.target.files.length; i++) {
        let file = e.target.files[i];
        let reader = new FileReader();
        reader.onload = (e) => {
          let file_base64 = e.target.result;
          self.documents.push({
            name: file.name,
            content: file_base64,
          });
        };
        reader.readAsDataURL(file);
      }
      this.uploadedFile = URL.createObjectURL(e.target.files[0]);
      e.target.value = null;
    },
    async deleteDocument(index, parameter_value_id, document_index) {
      if (this.documents[document_index].id) {
        let response = await request.post("/commitment/document/delete", {
          commitment_id: this.commitments[index].id,
          parameter_value_id: parameter_value_id,
          document_id: this.documents[document_index].id,
        });

        if (response.data.success === true) {
          this.documents.splice(document_index, 1);
          await this.getCommitments();
        }
      } else {
        this.documents.splice(document_index, 1);
      }
    },
    showDocument(index, parameter_value_id, document_index) {
      let document = this.documents[document_index];
      if (document["id"] !== undefined) {
        let commitment_id = this.commitments[index].id;

        let route = this.$router.resolve({
          path:
            "/commitment-document?commitment_id=" +
            commitment_id +
            "&parameter_value_id=" +
            parameter_value_id +
            "&document_id=" +
            document["id"],
        });
        window.open(route.href);
      } else {
        window.open(this.uploadedFile);
      }
    },
    closeDocumentModel() {
      this.documents = [];
    },
    getTypeValue(type_value) {
      return type_value.split(/[)(,.\s]/);
    },
    declarationConfirm() {
      // this.showConfirm1 = true;
      this.showConfirm2 = true;
    },
    cancel() {
      this.showConfirm2 = false;
    },
    async confirm() {
      this.showConfirm2 = false;
      this.errors = {};
      this.message = null;
      let commitmentIds = [];
      this.commitments.forEach(function (commitment) {
        commitmentIds.push(commitment.id);
      });
      const response = await request.get(
        "/commitment/attest-on-honor?ids=" + commitmentIds,
        {}
      );
    },
  },
};
</script>
<style>
.input-type-date {
  display: inline-block !important;
  max-width: 110px;
}

.ol-attribution {
  display: none;
}

.btn-tab {
  margin-bottom: 0 !important;
  background-color: #757575 !important;
}

.form-control {
  border: 1px solid;
}

.table thead th {
  padding: 0;
}

.commitment p,
.commitment option {
  word-break: break-all;
  white-space: normal;
}

.no-margin-b {
  margin-bottom: 0 !important;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  float: right;
  padding: 20px 30px;
  color: #000;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  background-color: #aec48a;
}

.modal-body {
  margin: 20px 0;
}
</style>
