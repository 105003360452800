<template>
  <header
    class="navbar navbar-dark sticky-top flex-md-nowrap p-0 shadow fixed-top"
    :style="{
      'background-image': 'url(' + headerBackground + ')',
      'background-size': 'cover',
      'background-color': '#FFF',
    }"
    id="top-navbar"
  >
    <!-- Navbar -->
    <nav
      class="
        navbar navbar-main navbar-expand-lg
        px-0
        mx-4
        shadow-none
        border-radius-xl
      "
      id="navbarBlur"
      navbar-scroll="true"
      style="width: 100%"
    >
      <div class="container-fluid py-1 px-3">
        <nav aria-label="breadcrumb">
          <ol
            class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5"
          >
            <li class="breadcrumb-item text-sm">
              <router-link tag="a" to="/">
                <img :src="require(`@/assets/img/logos/1.png`)" />
              </router-link>
            </li>
            <li class="text-sm text-dark" aria-current="page">
              <router-link tag="a" to="/">
                <img :src="require(`@/assets/img/logos/2.png`)" />
              </router-link>
            </li>
          </ol>
        </nav>
        <div
          class="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4"
          id="navbar"
        >
          <div class="ms-md-auto pe-md-3 d-flex align-items-center"></div>
          <ul class="navbar-nav justify-content-end">
            <li class="nav-item d-flex align-items-center">
              <router-link
                tag="a"
                to="/profile"
                class="nav-link text-body font-weight-bold px-0"
                v-if="is_logged == true"
              >
                <i class="fa fa-user me-sm-1"></i>
                <span class="d-sm-inline d-none">
                  {{ first_name }} {{ last_name }}
                </span>
              </router-link>
              <router-link
                tag="a"
                to="/login"
                class="nav-link text-body font-weight-bold px-0"
                v-else
              >
                <i class="fa fa-user me-sm-1"></i>
                <span class="d-sm-inline d-none">
                  {{ first_name }} {{ last_name }}
                </span>
              </router-link>
              <router-link
                tag="a"
                :to="{ name: 'Logout' }"
                class="nav-link text-body font-weight-bold px-0"
                v-if="is_logged == true"
              >
                <span class="d-sm-inline d-none"
                  >&nbsp;&nbsp;#&nbsp;&nbsp;Se Déconnecter</span
                >
              </router-link>
            </li>
            <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
              <a
                href="javascript:;"
                class="nav-link text-body p-0"
                id="iconNavbarSidenav"
                @click="toggleSidenav"
              >
                <div class="sidenav-toggler-inner">
                  <i class="sidenav-toggler-line"></i>
                  <i class="sidenav-toggler-line"></i>
                  <i class="sidenav-toggler-line"></i>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!-- End Navbar -->
  </header>
</template>

<script>
import $ from "jquery";
import request from "../../utils/request";
import localStorageManager from "../../utils/localStorageManager";

export default {
  components: {},
  data() {
    return {
      headerBackground: require("@/assets/img/header-background.png"),
      first_name: "",
      last_name: "",
      is_logged: false,
    };
  },
  mounted() {
    this.me();
    $(window).scroll(function () {
      const windowWidth = $(window).width();
      // if (windowWidth < 768) {
      scroll = $(window).scrollTop();
      if ($(window).scrollTop() > 500) {
        $("#top-navbar").removeClass("sticky-top");
      } else {
        $("#top-navbar").addClass("sticky-top");
      }
      // }
    });
  },
  methods: {
    toggleSidenav() {
      $("body").toggleClass("g-sidenav-pinned");
      $(".sidenav-main").toggleClass("bg-white").toggleClass("bg-transparent");
    },
    async me() {
      let token = localStorageManager.getItem("token");
      this.first_name = localStorageManager.getItem("first_name");
      this.last_name = localStorageManager.getItem("last_name");

      if (!this.first_name && !this.last_name) {
        if (!token) {
          if (
            this.$route.path !== "/login" &&
            this.$route.path !== "/update-password" &&
            this.$route.path !== "/"
          ) {
            await this.$router.push("/");
          }
        } else {
          const response = await request.get("/user/me", {});
          if (response.status === 200) {
            this.is_logged = true;
            let data = response.data.data;

            this.first_name = data.first_name;
            this.last_name = data.last_name;

            localStorageManager.setItem("first_name", data.first_name);
            localStorageManager.setItem("last_name", data.last_name);
          } else {
          }
        }
      } else {
        this.is_logged = true;
      }
    },
  },
};
</script>
