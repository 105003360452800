<template>
  <div>
    <header-component></header-component>
    <side-bar-component></side-bar-component>
    <main
      class="
        main-content
        position-relative
        max-height-vh-100
        h-100
        border-radius-lg
      "
    >
      <router-view></router-view>
      <footer-component></footer-component>
    </main>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>
