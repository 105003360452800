// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App";

// router setup
import routes from "./routes/routes";

// Plugins
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";

import "./assets/scss/main.scss";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import setupInterceptors from "./services/setupInterceptors";

setupInterceptors();

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkExactActiveClass: "nav-item active",
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("token") == null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      let role = localStorage.getItem("role");
      if (to.matched.some((record) => record.meta.is_supervisor)) {
        if (role === "supervisor") {
          next();
        } else {
          next({ name: "Index" });
        }
      } else if (to.matched.some((record) => record.meta.is_operator)) {
        if (role === "operator") {
          next();
        } else {
          next({ name: "Index" });
        }
      } else {
        next();
      }
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (localStorage.getItem("token") == null) {
      next();
    } else {
      next({ name: "Index" });
    }
  } else {
    next();
  }
});

Vue.use(VueRouter);
Vue.use(GlobalComponents);
Vue.use(GlobalDirectives);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
  data: {},
});
