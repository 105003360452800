<template>
  <div class="page-header align-items-start min-vh-75">
    <div class="container pt-10">
      <div class="row">
        <div class="col-lg-5 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-body">
              <h3 class="text-default font-weight-bolder text-center mt-2 mb-0">
                Connection en cours
              </h3>
              <h6 class="text-black font-weight-normal text-center mt-2 mb-0">
                Merci pour votre connexion
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import localStorageManager from "../../utils/localStorageManager";

export default {
  name: "LoginAs",
  components: {},
  data() {
    return {};
  },
  created() {
    this.loginAs();
  },
  methods: {
    async loginAs() {
      let token = this.$route.query.token;
      let role = this.$route.query.role;
      localStorageManager.setItem("token", token);
      localStorageManager.setItem("role", role);
      if (role === "supervisor") {
        await this.$router.push("/supervisor/");
      } else {
        await this.$router.push("/operator/");
      }
    },
  },
};
</script>
