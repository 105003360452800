<template>
  <div class="page-header align-items-start min-vh-75">
    <div class="container pt-10">
      <div class="row">
        <div class="col-lg-5 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-body">
              <h3 class="text-default font-weight-bolder text-center mt-2 mb-0">
                Réinitialisation
              </h3>
              <h6 class="text-black font-weight-normal text-center mt-2 mb-0">
                Réinitialiser votre mot de passe
              </h6>

              <div
                :class="'alert alert-' + message_status"
                role="alert"
                v-if="message"
              >
                {{ message }}
              </div>
              <form role="form" class="text-start">
                <div class="input-group input-group-outline py-2 my-4">
                  <label class="form-label my-2"
                    ><img
                      class="form-label-icon"
                      :src="require(`@/assets/img/icons/username.png`)"
                  /></label>
                  <input
                    type="email"
                    class="form-control py-3"
                    placeholder="Adresse email"
                    v-model="email"
                  />
                </div>
                <div class="text-center mt-3">
                  <button
                    type="button"
                    v-on:click="reset_password"
                    class="btn bg-default text-white w-50 my-4 mb-2 py-3"
                  >
                    Réinitialiser
                  </button>
                </div>
                <p class="mt-4 text-sm text-center">
                  <router-link
                    tag="a"
                    to="/login"
                    class="text-primary text-info font-weight-normal"
                  >
                    Retourner vers la page login
                  </router-link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "../../utils/request";
import localStorageManager from "../../utils/localStorageManager";

export default {
  name: "ResetPassword",
  data() {
    return {
      email: "",
      message: "",
      message_status: "success",
    };
  },
  mounted() {},
  methods: {
    async reset_password() {
      const response = await request.post(
        "/user/reset-password",
        {
          email: this.email,
        },
        {},
        true
      );

      let data = response.data;
      this.message = data.message;
      if (response.status === 200) {
        this.message_status = "success";
        localStorageManager.setItem("email", this.email);
        let self = this;
        setTimeout(function () {
          self.$router.push("/login");
        }, 1000);
      } else if (response.status === 400) {
        this.message_status = "warning";
      } else {
        this.message_status = "danger";
      }
    },
  },
};
</script>

<style scoped></style>
