<template>
  <div class="page-header align-items-start min-vh-75" v-on:on:keyup.13="login">
    <div class="container pt-10">
      <div class="row">
        <div class="col-lg-5 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-body">
              <h3 class="text-default font-weight-bolder text-center mt-2 mb-0">
                Connexion
              </h3>
              <div
                :class="'alert alert-' + message_status"
                role="alert"
                v-if="message"
              >
                {{ message }}
              </div>
              <form role="form" class="text-start" @keyup.enter="login">
                <div class="input-group input-group-outline py-2 my-4">
                  <label class="form-label my-2"
                    ><img
                      class="form-label-icon"
                      :src="require(`@/assets/img/icons/password.png`)"
                  /></label>
                  <input
                    type="email"
                    class="form-control py-3"
                    placeholder="Adresse email"
                    v-model="email"
                  />
                </div>
                <div class="input-group input-group-outline py-2">
                  <label class="form-label my-2"
                    ><img
                      class="form-label-icon"
                      :src="require(`@/assets/img/icons/password.png`)"
                  /></label>
                  <input
                    type="password"
                    id="password"
                    placeholder="Mot de passe"
                    v-model="password"
                    class="form-control pl-1"
                  />
                </div>
                <div class="text-center mt-3">
                  <button
                    type="button"
                    class="btn bg-default text-white w-50 my-4 mb-2 py-3"
                    :disabled="loginInProgress"
                    v-on:click="login"
                  >
                    Se Connecter
                  </button>
                </div>
                <p class="mt-4 text-sm text-center">
                  <router-link
                    tag="a"
                    to="/reset-password"
                    class="text-primary text-info font-weight-normal"
                  >
                    Identifiant ou mot de passe oublié ?
                  </router-link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import request from "../../utils/request";
import localStorageManager from "../../utils/localStorageManager";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      email: "",
      password: "",
      message: "",
      message_status: "success",
      loginInProgress: false,
    };
  },
  mounted() {
    let self = this;
    $("body").keydown(function (e) {
      if (e.keyCode === 13) {
        self.login(e);
      }
    });
  },
  methods: {
    async login(e) {
      this.loginInProgress = true;
      $(e.target).prop("disabled", true);
      e.preventDefault();
      const response = await request.post(
        "/auth/login",
        {
          email: this.email,
          password: this.password,
        },
        {},
        true
      );
      $(e.target).prop("disabled", false);

      this.message = response.data.detail;
      if (response.status === 200) {
        let role = response.data.role;
        localStorageManager.setItem("token", response.data.access);
        localStorageManager.setItem("refresh", response.data.refresh);
        localStorageManager.setItem("role", response.data.role);
        this.message_status = "success";
        if (role === "supervisor") {
          await this.$router.push("/supervisor/");
        } else {
          await this.$router.push("/operator/");
        }
      } else if (response.status === 400 || response.status === 401) {
        this.message_status = "warning";
      } else {
        this.message_status = "danger";
      }
      this.loginInProgress = false;
    },
  },
};
</script>
