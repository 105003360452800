<template>
  <div class="container-fluid py-4">
    <div class="row mb-4">
      <div class="col-lg-8 col-md-8 mb-md-0 mb-4">
        <div class="card">
          <div class="card-header p-3">
            <div class="row">
              <div class="col-lg-12">
                <h6 v-if="page">{{ page.title }}</h6>
                <p class="text-sm mb-0" v-if="page" v-html="page.content"></p>
              </div>
            </div>
          </div>
          <div class="card-body pb-3 p-3 h-auto">
            <div class="row">
              <div class="col-md-12">
                <p>Visualisation des secteurs</p>
              </div>
              <div class="col-md-12">
                <map-component :geoJson="geoJson"></map-component>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4">
        <div class="card h-100">
          <div class="card-header pb-0">
            <h6>Visualisation des statistiques par secteur</h6>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-md-12">
                <span>Choisissez l'échelle de visualisation:</span>
              </div>
              <div class="col-md-12">
                <b-form-select
                  id="per-page-select"
                  v-model="category"
                  :options="categories"
                  value-field="id"
                  text-field="name"
                  size="sm"
                  style="
                    padding: 8px;
                    border-radius: 5px;
                    background: transparent;
                  "
                  @change="getSubCategoryList"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled>
                      -- Please select an option --
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </div>
            </div>
            <div class="row" v-if="sub_categories.length > 0">
              <div class="col-md-12">
                <span>Choisissez l'élément à visualiser:</span>
              </div>
              <div class="col-md-12">
                <b-form-select
                  id="per-page-select"
                  v-model="sub_category"
                  :options="sub_categories"
                  value-field="id"
                  text-field="name"
                  @change="getYearList"
                  size="sm"
                  style="
                    padding: 8px;
                    border-radius: 5px;
                    background: transparent;
                  "
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled>
                      -- Please select an option --
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </div>
            </div>
            <div class="row" v-if="years.length > 0">
              <div class="col-md-12">
                <span>Choisissez l'année :</span>
              </div>
              <div class="col-md-12">
                <b-form-select
                  id="per-page-select"
                  v-model="year"
                  :options="years"
                  value-field="year_ref"
                  text-field="year_ref"
                  @change="getSector"
                  size="sm"
                  style="
                    padding: 8px;
                    border-radius: 5px;
                    background: transparent;
                  "
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled>
                      -- Please select an option --
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </div>
            </div>
            <hr />
            <div
              class="row"
              v-for="indicator_group in this.indicators"
              v-bind:key="indicator_group.id"
            >
              <div class="col-md-12" v-if="indicator_group.indicators.length">
                <h6>{{ indicator_group.name }}</h6>
                <ul class="p-0">
                  <li
                    class="list-unstyled"
                    v-for="indicator in indicator_group.indicators"
                    v-bind:key="indicator.id"
                  >
                    {{ indicator.name }} : {{ indicator.value }}
                    {{ indicator.sample_value }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  components: {},
  data: () => ({
    plots: [],
    geoJson: {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "MultiPolygon",
            coordinates: [],
          },
        },
      ],
    },
    perPage: 10,
    currentPage: 1,
    totalRows: 0,
    category: null,
    categories: [],
    sub_category: null,
    sub_categories: [],
    element: "Mauzé sur le Mignon",
    elements: ["Mauzé sur le Mignon", "Saint Sauvant", "Epannes"],
    year: null,
    years: [],
    indicators: [],
    pageSlug: "public-dashboard",
    page: null,
  }),
  created() {
    document.title = "Tableau de bord public";
    this.resetGeoJson();
  },
  mounted() {
    this.getPageContent();
    this.getCategoryList();
  },
  methods: {
    async getCategoryList() {
      const response = await request.get("/sector/category/list", {});
      if (response.status === 200) {
        this.categories = response.data.data;
      } else {
      }
      this.years = [];
      this.sub_categories = [];
      this.indicators = [];
    },
    async getSubCategoryList() {
      const response = await request.get("/sector/sub-category/list", {
        params: {
          category: this.category,
        },
      });
      this.indicators = [];
      this.years = [];
      this.sub_category = null;
      this.year = null;
      if (response.status === 200) {
        this.sub_categories = response.data.data;
      } else {
        this.sub_categories = [];
      }
    },
    async getYearList() {
      const response = await request.get("/sector/year/list", {
        params: {
          sub_category: this.sub_category,
        },
      });
      this.year = null;
      this.indicators = null;
      if (response.status === 200) {
        this.years = response.data.data;
      } else {
        this.years = [];
      }
    },
    async getSector() {
      if (
        this.years === null ||
        this.sub_category === null ||
        this.year === null
      ) {
        return;
      }
      const response = await request.post("/sector/sector/list", {
        category: this.category,
        sub_category: this.sub_category,
        year: this.year,
      });

      if (response.status === 200) {
        this.geoJson = response.data.data;
        await this.getIndicators(response.data.data.features[0].id);
      } else {
      }
    },
    async getIndicators(sectorId) {
      const response = await request.post("/sector/indicator-sector/list", {
        sector_id: sectorId,
        sub_category: this.sub_category,
        region: this.region,
        year: this.year,
      });
      if (response.status === 200) {
        this.indicators = response.data.data;
      }
    },
    async getPageContent() {
      const response = await request.get("/cms-page/" + this.pageSlug, {});
      if (response.status === 200) {
        this.page = response.data.data;
      } else {
        // Redirect 404
      }
    },
    resetGeoJson() {
      this.geoJson = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [
                [
                  [-27.0703125, 43.58039085560784],
                  [-27.0703125, 43.58039085560784],
                  [-27.0703125, 43.58039085560784],
                  [-27.0703125, 43.58039085560784],
                ],
              ],
            },
          },
        ],
      };
    },
  },
};
</script>
<style>
.ol-attribution {
  display: none;
}

.form-control {
  border: 1px solid;
}

.table thead th {
  padding: 0;
}
</style>
