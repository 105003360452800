<template>
  <div
    ref="map-root"
    style="width: 100%; height: 100%; min-height: 520px"
  ></div>
</template>

<script>
import View from "ol/View";
import Map from "ol/Map";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import { Fill, Icon, Stroke, Style } from "ol/style";
import "ol/ol.css";
import CircleStyle from "ol/style/Circle";

export default {
  name: "MapComponent",
  components: {},
  props: {
    geoJson: Object,
    data_set_type_id: null,
    remove_data_set_type_id: null,
    push_in_data_set_type: null,
    clear_map: null,
  },
  data: () => ({
    olMap: null,
    vectorLayer: null,
    selectedFeature: null,
  }),
  mounted() {
    this.vectorLayer = new VectorLayer({
      source: new VectorSource({
        features: [],
      }),
    });

    this.olMap = new Map({
      target: this.$refs["map-root"],
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        this.vectorLayer,
      ],
      view: new View({
        zoomFactor: 2,
        zoom: 2,
        maxZoom: 20,
        center: [0, 0],
      }),
    });
  },
  watch: {
    geoJson(value) {
      this.updateSource(value);
    },
    remove_data_set_type_id(value) {
      this.removeLayer(value);
    },
    clear_map(value) {
      this.clearMap(value);
    },
  },
  methods: {
    updateSource(geoJson) {
      if (geoJson) {
        const view = this.olMap.getView();
        let geoJsonStyle = null;

        if (geoJson.style) {
          geoJsonStyle = JSON.parse(geoJson.style.trim());
        }

        const features = new GeoJSON({
          featureProjection: "EPSG:3857",
        }).readFeatures(geoJson.geoJson);

        let circle = new CircleStyle({
          radius: 3,
          fill: new Fill({ color: geoJsonStyle.color }),
          stroke: new Stroke({ color: geoJsonStyle.color, width: 1 }),
        });

        let styles = {
          Point: new Style({
            image: circle,
          }),
          LineString: new Style({
            stroke: new Stroke({
              color: geoJsonStyle.color,
              width: 3,
            }),
          }),
          MultiLineString: new Style({
            stroke: new Stroke({
              color: geoJsonStyle.color,
              width: 3,
            }),
          }),
          MultiPoint: new Style({
            image: circle,
          }),
          MultiPolygon: new Style({
            stroke: new Stroke({
              color: geoJsonStyle.color,
              width: 3,
            }),
          }),
          Polygon: new Style({
            stroke: new Stroke({
              color: geoJsonStyle.color,
              width: 3,
            }),
          }),
          GeometryCollection: new Style({
            stroke: new Stroke({
              color: geoJsonStyle.color,
              width: 3,
            }),
            fill: new Fill({
              color: geoJsonStyle.color,
            }),
          }),
          Circle: new Style({
            stroke: new Stroke({
              color: geoJsonStyle.color,
              width: 3,
            }),
            fill: new Fill({
              color: geoJsonStyle.color,
            }),
          }),
        };

        let styleFunction = function (feature) {
          return styles[feature.getGeometry().getType()];
        };

        let vectorSource = new VectorSource({
          features: features,
        });

        let vectorLayer = new VectorLayer({
          source: vectorSource,
          style: styleFunction,
          name: this.data_set_type_id,
        });

        if (this.push_in_data_set_type) {
          let val = this.data_set_type_id;
          this.olMap
            .getLayers()
            .getArray()
            .filter((layer) => layer.get("name") === parseInt(val))
            .forEach((layer) => {
              layer.getSource().addFeatures(features);
            });

          view.fit(vectorLayer.getSource().getExtent());
        } else {
          this.olMap.getLayers().push(vectorLayer);

          if (geoJson.geoJson.features.length !== 0) {
            vectorLayer.getSource().clear();
            vectorLayer.getSource().addFeatures(features);
            view.fit(vectorLayer.getSource().getExtent());
          }
        }
      }
    },
    removeLayer(val) {
      this.olMap
        .getLayers()
        .getArray()
        .filter((layer) => layer.get("name") === parseInt(val))
        .forEach((layer) => this.olMap.removeLayer(layer));
    },
    clearMap(val) {
      if (val === true) {
        this.olMap
          .getLayers()
          .getArray()
          .filter((layer) => layer.get("name") !== undefined)
          .forEach((layer) => this.olMap.removeLayer(layer));
      }
    },
  },
};
</script>
