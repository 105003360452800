<template>
  <div class="container-fluid py-4">
    <div class="row mb-4">
      <div id="map-container" class="col-lg-7 col-md-6 mb-md-0 mb-4">
        <div class="card">
          <div class="card-header p-3">
            <div class="row">
              <div>
                <h6 v-if="page">{{ page.title }}</h6>
                <p class="text-sm mb-0" v-if="page" v-html="page.content"></p>
              </div>
            </div>
          </div>
          <div class="card-body pb-3 p-3 h-auto">
            <div class="row">
              <div class="col-md-12">
                <p>Visualisation des parcelles</p>
              </div>
              <div class="col-md-12">
                <map-component
                  ref="map-component"
                  :geoJson="geoJson"
                ></map-component>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-md-6">
        <div class="card h-100">
          <div class="card-header pb-0">
            <h6>Sélection des données à afficher</h6>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-md-6">
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="perPageOptions"
                  @change="onPerPageChange"
                  size="sm"
                  style="
                    padding: 8px;
                    border-radius: 5px;
                    background: transparent;
                  "
                ></b-form-select>
              </div>
              <div class="col-md-6">
                <b-form-input
                  id="query-input"
                  v-model="query"
                  type="search"
                  placeholder="Recherche"
                  style="border: 1px solid; padding: 5px"
                  @update="onUpdate"
                  @change="onSearch"
                  separator="false"
                ></b-form-input>
              </div>
            </div>
            <hr />
            <b-table
              :sticky-header="true"
              fixed
              responsive
              bordered
              id="plots"
              :items="plots.features"
              :fields="fields"
              :per-page="perPage"
              :select-mode="selectMode"
              @filtered="onFiltered"
              ref="plots"
              selectable
              @row-selected="onRowSelected"
              @row-unselected="alert(1)"
            >
            </b-table>
            <div class="row">
              <b-col md="4">
                <ul class="pagination">
                  <li class="page-item active">
                    <p class="font-weight-100 gray">Total {{ totalRows }}</p>
                  </li>
                </ul>
              </b-col>
              <b-col md="8">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  aria-controls="plots"
                  align="right"
                  @change="onPageChange"
                  hide-separator-buttons
                ></b-pagination>
              </b-col>
            </div>
            <hr />
            <template v-if="message">
              <div
                :class="'alert alert-' + message_status"
                role="alert"
                v-if="message"
              >
                {{ message }}
              </div>
            </template>
            <template v-if="selectedItem">
              <h6>Saisie de données à la parcelle</h6>
              <table>
                <tr>
                  <td>
                    <b-form-group>
                      <b-form-checkbox
                        id="irrigable"
                        name="irrigable"
                        v-model="irrigable"
                      >
                        Irrigable
                      </b-form-checkbox>
                      <b-form-invalid-feedback class="d-block">
                        <template v-if="errors && errors.irrigable">
                          {{ errors.irrigable[0] }}
                        </template>
                        <template v-else>
                          <span>&nbsp;</span>
                        </template>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </td>
                  <td>
                    <b-form-group>
                      <b-form-checkbox
                        id="irrigated"
                        name="irrigated"
                        v-model="irrigated"
                      >
                        Irriguée
                      </b-form-checkbox>
                      <b-form-invalid-feedback class="d-block">
                        <template v-if="errors && errors.irrigated">
                          {{ errors.irrigated[0] }}
                        </template>
                        <template v-else>
                          <span>&nbsp;</span>
                        </template>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </td>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <td>Irrigation (m³)</td>
                  <td>Intrants</td>
                  <td>Origine de l'apport en eau</td>
                </tr>
                <tr>
                  <td>
                    <b-form-group>
                      <b-form-input
                        id="volume-of-water-consumed-input"
                        v-model="volume_of_water_consumed"
                        type="text"
                        placeholder="Volume d'eau"
                        style="
                          border: 1px solid;
                          padding: 5px;
                          max-width: 190px;
                        "
                        separator="false"
                        :disabled="irrigated == false"
                      ></b-form-input>
                      <b-form-invalid-feedback class="d-block">
                        <template
                          v-if="errors && errors.volume_of_water_consumed"
                        >
                          {{ errors.volume_of_water_consumed[0] }}
                        </template>
                        <template v-else>
                          <span>&nbsp;</span>
                        </template>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </td>
                  <td>
                    <b-form-group>
                      <b-form-input
                        id="ift-input"
                        v-model="ift"
                        type="number"
                        step="0.1"
                        max="9999999.99"
                        placeholder="IFT"
                        style="border: 1px solid; padding: 5px"
                        separator="false"
                        @keyup="validate_digits"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback class="d-block">
                        <template v-if="errors && errors.ift">
                          {{ errors.ift[0] }}
                        </template>
                        <template v-else>
                          <span>&nbsp;</span>
                        </template>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </td>
                  <td>
                    <b-form-group>
                      <b-form-select
                        id="per-page-select"
                        v-model="water_origin"
                        :options="water_origins"
                        :disabled="irrigated === false"
                        size="sm"
                        style="
                          padding: 8px;
                          border-radius: 5px;
                          background: transparent;
                          max-width: 190px;
                        "
                      >
                        <template #first>
                          <b-form-select-option :value="null" disabled>
                            -- Origine de l'apport --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <b-form-invalid-feedback class="d-block">
                        <template v-if="errors && errors.water_origin">
                          {{ errors.water_origin[0] }}
                        </template>
                        <template v-else>
                          <span>&nbsp;</span>
                        </template>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </td>
                </tr>
                <tr>
                  <td colspan="3"></td>
                </tr>
              </table>
              <table>
                <tr>
                  <td>Culture</td>
                  <td>Dates de mise en place</td>
                  <td>Dates de récolte (destruction)</td>
                  <td>CIPAN</td>
                  <td>Dérobée</td>
                </tr>
                <tr
                  v-for="(culture_form, index) in culture_forms"
                  v-bind:key="index"
                >
                  <td>
                    <b-form-group>
                      <b-form-select
                        id="per-page-select"
                        v-model="culture_forms[index].culture_id"
                        :options="cultures"
                        value-field="id"
                        text-field="name"
                        size="sm"
                        style="
                          padding: 8px;
                          border-radius: 5px;
                          background: transparent;
                          max-width: 190px;
                        "
                      >
                        <template #first>
                          <b-form-select-option :value="null" disabled>
                            -- Cultures --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <b-form-invalid-feedback class="d-block">
                        <template
                          v-if="
                            errors &&
                            errors.cultures &&
                            errors.cultures.length > index &&
                            errors.cultures[index].culture_id
                          "
                        >
                          {{ errors.cultures[index].culture_id[0] }}
                        </template>
                        <template v-else>
                          <span>&nbsp;</span>
                        </template>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </td>
                  <td>
                    <b-form-group>
                      <b-form-datepicker
                        :id="'start-date-' + index"
                        placeholder="Début"
                        v-model="culture_forms[index].start_date"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }"
                        locale="fr"
                        class="start-date"
                      ></b-form-datepicker>
                      <b-form-invalid-feedback class="d-block">
                        <template
                          v-if="
                            errors &&
                            errors.cultures &&
                            errors.cultures.length > index &&
                            errors.cultures[index].start_date
                          "
                        >
                          {{ errors.cultures[index].start_date[0] }}
                        </template>
                        <template v-else>
                          <span>&nbsp;</span>
                        </template>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </td>
                  <td>
                    <b-form-group>
                      <b-form-datepicker
                        :id="'end-date-' + index"
                        placeholder="Fin"
                        v-model="culture_forms[index].end_date"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }"
                        locale="fr"
                        class="start-date"
                      ></b-form-datepicker>
                      <b-form-invalid-feedback class="d-block">
                        <template
                          v-if="
                            errors &&
                            errors.cultures &&
                            errors.cultures.length > index &&
                            errors.cultures[index].end_date
                          "
                        >
                          <span
                            v-html="errors.cultures[index].end_date[0]"
                          ></span>
                        </template>
                        <template v-else>
                          <span>&nbsp;</span>
                        </template>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </td>
                  <td style="text-align: center">
                    <b-form-group>
                      <b-form-checkbox
                        :id="'cipan-' + index"
                        name="cipan"
                        v-model="culture_forms[index].cipan"
                        @change="onCipanChange($event, index)"
                      >
                      </b-form-checkbox>
                      <b-form-invalid-feedback class="d-block">
                        <template
                          v-if="
                            errors &&
                            errors.cultures &&
                            errors.cultures.length > index &&
                            errors.cultures[index].cipan
                          "
                        >
                          <span v-html="errors.cultures[index].cipan[0]"></span>
                        </template>
                        <template v-else>
                          <span>&nbsp;</span>
                        </template>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </td>
                  <td style="text-align: center">
                    <b-form-group>
                      <b-form-checkbox
                        :id="'stolen-' + index"
                        name="stolen"
                        v-model="culture_forms[index].stolen"
                        @change="onStolenChange($event, index)"
                      >
                      </b-form-checkbox>
                      <b-form-invalid-feedback class="d-block">
                        <template
                          v-if="
                            errors &&
                            errors.cultures &&
                            errors.cultures.length > index &&
                            errors.cultures[index].stolen
                          "
                        >
                          <span
                            v-html="errors.cultures[index].stolen[0]"
                          ></span>
                        </template>
                        <template v-else>
                          <span>&nbsp;</span>
                        </template>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </td>
                </tr>
                <tr>
                  <td colspan="3">&nbsp;</td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td align="right" colspan="4">
                    <input
                      type="button"
                      value="-"
                      class="btn bg-default text-white"
                      @click="deleteForm"
                    />
                    &nbsp;&nbsp;
                    <input
                      type="button"
                      value="+"
                      class="btn bg-default text-white"
                      @click="addForm"
                    />
                    &nbsp;&nbsp;
                    <input
                      type="button"
                      value="Enregister"
                      class="btn bg-default text-white"
                      @click="save"
                      :disabled="process_save"
                    />
                  </td>
                </tr>
              </table>
              <hr />
              <h6>Cumuls des surfaces calculées à l'exploitation</h6>
              <table>
                <tr>
                  <td>Somme des volumes d'eau déclarés (m³)</td>
                  <td>&nbsp;</td>
                  <td>Surface irrigable (ha)</td>
                </tr>
                <tr>
                  <td>
                    <b-form-input
                      id="irrigable-sum-volume-input"
                      v-model="irrigable_sum_volume"
                      type="text"
                      placeholder="Somme des volumes d'eau déclarés"
                      style="border: 1px solid; padding: 5px"
                      separator="false"
                      disabled
                    ></b-form-input>
                  </td>
                  <td>&nbsp;</td>
                  <td>
                    <b-form-input
                      id="surface-irrigable-input"
                      v-model="surface_irrigable"
                      type="text"
                      placeholder="Surface irrigable en ha"
                      style="border: 1px solid; padding: 5px"
                      separator="false"
                      disabled
                    ></b-form-input>
                  </td>
                </tr>
                <tr>
                  <td>Surface totale des parcelles (ha)</td>
                  <td>&nbsp;</td>
                  <td>Volumes d'irrigation autorisés par l'OUGC</td>
                </tr>
                <tr>
                  <td>
                    <b-form-input
                      id="surface-input"
                      v-model="surface"
                      type="text"
                      placeholder="Surface totale des parcelles"
                      style="border: 1px solid; padding: 5px"
                      separator="false"
                      disabled
                    ></b-form-input>
                  </td>
                  <td>&nbsp;</td>
                  <td>
                    <b-form-input
                      id="surface-input"
                      v-model="authorized_vol_per_year"
                      type="text"
                      placeholder="Volumes d'irrigation autorisés par l'OUGC"
                      style="border: 1px solid; padding: 5px"
                      separator="false"
                      disabled
                    ></b-form-input>
                  </td>
                  <td></td>
                </tr>
              </table>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  components: {},
  data: () => ({
    pageSlug: "parcel-declaration",
    page: null,
    plots: [],
    geoJson: {},
    features: {},
    perPage: 10,
    currentPage: 1,
    totalRows: 0,
    perPageOptions: [10, 20, 30, 50],
    fields: [
      {
        key: "properties.park_code",
        label: "Numéro Parcelle",
        filterByFormatted: true,
      },
      {
        key: "properties.culture__culture_code",
        label: "Code Culture",
        filterByFormatted: true,
      },
      {
        key: "properties.d1_cultural__culture_code",
        label: "Culture D1",
        filterByFormatted: true,
      },
      {
        key: "properties.d2_cultural__culture_code",
        label: "Culture D2",
        filterByFormatted: true,
      },
      {
        key: "properties.bio",
        label: "Bio",
        filterByFormatted: true,
        formatter: (value, key, item) => {
          return value === "true" ? "Oui" : "Non";
        },
      },
    ],
    query: null,
    selectedItem: null,
    selectMode: "single",
    volume_of_water_consumed: null,
    ift: null,
    water_origin: null,
    water_origins: ["", "réserve", "forage", "rivière"],
    culture_forms: [
      {
        culture_id: null,
        start_date: null,
        end_date: null,
        cipan: 0,
        stolen: 0,
      },
    ],
    culture: [null],
    cultures: [],
    start_date: null,
    end_date: null,
    message: null,
    message_status: null,
    irrigable: 0,
    irrigated: 0,
    cipan: 0,
    stolen: 0,
    irrigable_sum_volume: 0,
    surface_irrigable: 0,
    authorized_vol_per_year: 0,
    surface: 0,
    errors: {},
    process_save: false,
  }),
  watch: {
    irrigated(newValue, oldValue) {
      if (newValue === false) {
        this.volume_of_water_consumed = null;
      }
    },
  },
  created() {
    this.resetGeoJson();
    document.title = "Déclaration à la parcelle";
  },
  mounted() {
    this.getPageContent();
    this.get_plot_list();
    this.get_cultures();
    this.get_consumed_volume();
  },
  methods: {
    async get_plot_list() {
      const response = await request.get("/plot/list", {
        params: {
          page: this.currentPage,
          limit: this.perPage,
          q: this.query,
        },
      });
      if (response.status === 200) {
        let data = response.data.data;
        this.plots = data.results;
        this.geoJson = this.plots;
        this.totalRows = data.count;
      }
    },
    async get_cultures() {
      const response = await request.get("/plot/culture/list", []);
      if (response.status === 200) {
        this.cultures = response.data.data;
        this.cultures.forEach(function (item, index) {
          item.name = item.culture_code + " - " + item.name;
        });
      } else {
        this.cultures = {};
      }
    },
    async get_plot_culture(plot_id) {
      const response = await request.get(
        "/plot/plot-culture/list/" + plot_id,
        []
      );
      if (response.status === 200) {
        this.get_consumed_volume();
        if (response.data.data.length > 0) {
          this.culture_forms = response.data.data;
        }
      }
    },
    async get_consumed_volume() {
      const response = await request.get("/plot/consumed-volume", {});
      if (response.status === 200) {
        this.irrigable_sum_volume =
          response.data.data.irrigable_sum_volume || 0;
        this.surface_irrigable = response.data.data.surface_irrigable || 0;
        this.surface = response.data.data.surface || 0;
        this.authorized_vol_per_year =
          response.data.data.authorized_vol_per_year;
      } else {
        this.irrigable_sum_volume = 0;
        this.surface_irrigable = 0;
        this.surface = 0;
      }
    },
    async save() {
      this.process_save = true;
      this.errors = {};
      this.message = null;
      if (!this.selectedItem) {
        this.process_save = false;
        return;
      }
      const response = await request.post("/plot/add/" + this.selectedItem.id, {
        volume_of_water_consumed: this.volume_of_water_consumed || null,
        water_origin: this.water_origin || null,
        ift: parseFloat(this.ift) || null,
        cultures: this.culture_forms,
        irrigable: this.irrigable,
        irrigated: this.irrigated,
      });
      this.message = response.data.message;

      if (response.status === 200) {
        this.message_status = "success";
        this.irrigable_sum_volume =
          response.data.data.irrigable_sum_volume || 0;
        this.surface_irrigable = response.data.data.surface_irrigable || 0;
        this.surface = response.data.data.surface || 0;
        await this.get_plot_list();
        this.resetForm();
      } else {
        this.message_status = "warning";
        let culture_forms = this.culture_forms;
        this.culture_forms = [];
        this.errors = response.data.errors;
        this.culture_forms = culture_forms;
      }
      this.process_save = false;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async onRowSelected(items) {
      this.resetForm();
      this.selectedItem = null;

      if (items.length) {
        this.selectedItem = items[0];
        this.$refs["map-component"].setSelectedFeatureById(
          this.selectedItem.id
        );

        this.irrigable = this.selectedItem.properties.irrigable;
        this.irrigated = this.selectedItem.properties.irrigated;
        this.volume_of_water_consumed =
          this.selectedItem.properties.volume_of_water_consumed;
        this.ift = this.selectedItem.properties.ift;
        this.water_origin = this.selectedItem.properties.water_origin;
        await this.get_plot_culture(this.selectedItem.id);
      } else {
        await this.resetGeoJson();
        this.geoJson = this.plots;
      }
    },
    onPageChange(page) {
      this.currentPage = page;
      this.get_plot_list();
    },
    onPerPageChange() {
      this.get_plot_list();
    },
    onSearch(query) {
      this.query = query;
      this.currentPage = 1;
      this.get_plot_list();
    },
    onUpdate(query) {
      this.query = query;
      this.currentPage = 1;
      this.get_plot_list();
    },
    addForm() {
      this.culture_forms.push({
        culture_id: null,
        start_date: null,
        end_date: null,
        cipan: 0,
        stolen: 0,
      });
    },
    deleteForm() {
      if (this.culture_forms.length > 1) {
        this.culture_forms.pop();
      }
    },
    resetForm() {
      this.volume_of_water_consumed = null;
      this.ift = null;
      this.water_origin = null;
      this.culture_forms = [
        {
          culture_id: null,
          start_date: null,
          end_date: null,
          cipan: 0,
          stolen: 0,
        },
      ];
      this.culture = [null];
      this.start_date = null;
      this.end_date = null;
      this.cipan = 0;
      this.stolen = 0;
      this.message = null;
      this.message_status = null;
      this.irrigable = 1;
      this.irrigated = 0;
    },
    resetGeoJson() {
      this.geoJson = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [-27.0703125, 43.58039085560784],
              [-27.0703125, 43.58039085560784],
              [-27.0703125, 43.58039085560784],
              [-27.0703125, 43.58039085560784],
            ],
          ],
        },
      };
    },
    setSelectedFeature(feature) {
      let self = this;
      this.$refs.plots.items.forEach(function (item, index) {
        if (feature.getId() === item.id) {
          self.$refs.plots.selectRow(index);
        }
      });
    },
    async getPageContent() {
      const response = await request.get("/cms-page/" + this.pageSlug, {});
      if (response.status === 200) {
        this.page = response.data.data;
      } else {
        // Redirect 404
      }
    },
    validate_digits($event) {
      if ($event.key === "Backspace") {
        return true;
      }

      const [number, int] = [
        parseFloat($event.target.value),
        parseInt($event.target.value),
      ];

      this.ift =
        number > $event.target.max
          ? $event.target.max
          : number === int
          ? int
          : number.toFixed(2);
    },
    onCipanChange($event, index) {
      if ($event === true) {
        this.culture_forms[index].stolen = false;
      }
    },
    onStolenChange($event, index) {
      if ($event === true) {
        this.culture_forms[index].cipan = false;
      }
    },
  },
};
</script>
<style>
.ol-attribution {
  display: none;
}

.form-control {
  border: 1px solid;
}

.table thead th {
  padding: 0;
}
</style>
