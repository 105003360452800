import axios from "axios";
import localStorageManager from "./localStorageManager";
import api from "../services/api";

const MEDIA_URL_DEV = "http://localhost/media";
const MEDIA_URL_PREPROD = "http://api.epmp.studiolab.fr/media";
const MEDIA_URL_PROD = "https://api.observatoire-eau1779.fr/media";
const API_DEV = "http://localhost/api";
const API_PROD = "https://api.observatoire-eau1779.fr/api";
const API_PREPROD = "http://api.epmp.studiolab.fr/api";
let API_URL = process.env.NODE_ENV === "production" ? API_PROD : API_DEV;
let MEDIA_URL =
  process.env.NODE_ENV === "production" ? MEDIA_URL_PROD : MEDIA_URL_DEV;

if (process.env.NODE_ENV === "preprod") {
  API_URL = API_PREPROD;
  MEDIA_URL = MEDIA_URL_PREPROD;
}

export default {
  post: async function (path, data, config = {}, isPublic = false) {
    config = this.setBearerInConfig(config, isPublic);
    return api
      .post(path, data, config)
      .then((response) => {
        this.checkAuth(response);
        return response;
      })
      .catch((error) => {
        let response = error.response;
        this.checkAuth(response);
        if (response) {
          return response;
        } else {
          return this.getServerErrorResponse();
        }
      });
  },
  get: async function (path, config = [], isPublic = false) {
    config = this.setBearerInConfig(config, isPublic);

    return api
      .get(path, config)
      .then((response) => {
        this.checkAuth(response);
        return response;
      })
      .catch((error) => {
        let response = error.response;
        this.checkAuth(response);
        if (response) {
          return response;
        } else {
          return this.getServerErrorResponse();
        }
      });
  },
  put: async function (path, data, config = [], isPublic = false) {
    config = this.setBearerInConfig(config, isPublic);

    return api
      .put(path, data, config)
      .then((response) => {
        this.checkAuth(response);
        return response;
      })
      .catch((error) => {
        let response = error.response;
        this.checkAuth(response);

        if (response) {
          return response;
        } else {
          return this.getServerErrorResponse();
        }
      });
  },

  delete: async function (path, config = [], isPublic = false) {
    config = this.setBearerInConfig(config, isPublic);

    return api
      .delete(path, config)
      .then((response) => {
        this.checkAuth(response);
        return response;
      })
      .catch((error) => {
        let response = error.response;
        this.checkAuth(response);
        if (response) {
          return response;
        } else {
          return this.getServerErrorResponse();
        }
      });
  },
  setBearerInConfig(config, isPublic = false) {
    if (!isPublic) {
      config.headers = {
        Authorization: "Bearer " + localStorageManager.getItem("token"),
      };
    }
    return config;
  },
  checkAuth(response) {
    if (
      response !== undefined &&
      response.status &&
      parseInt(response.status) === 401
    ) {
      localStorageManager.removeAll();
      location.reload();
    }
  },
  getServerErrorResponse() {
    return {
      status: 500,
      data: {
        message: "Unable to make request, please try later",
      },
    };
  },
  getMediaUrl() {
    return MEDIA_URL;
  },
  getBaseUrl() {
    return API_URL;
  },
};
