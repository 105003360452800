<template>
  <footer class="footer py-4">
    <br />
    <br />
    <br />
    <br />
    <br />
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="col-lg-6 mb-lg-0 mb-4">
          <div class="copyright text-center text-sm text-muted text-lg-start">
            © 2022 , Etablissement public du Marais poitevin
          </div>
        </div>
        <div class="col-lg-6">
          <div class="text-right text-sm text-muted">
            <a href="mailto:observatoire.eau@epmp-marais-poitevin.fr"
              >observatoire.eau@epmp-marais-poitevin.fr</a
            >
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style></style>
