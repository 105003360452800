<template>
  <div class="page-header align-items-start min-vh-75">
    <div class="container pt-10">
      <div class="row">
        <div class="col-lg-5 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-body">
              <h3 class="text-default font-weight-bolder text-center mt-2 mb-0">
                Profile
              </h3>
              <div
                :class="'alert alert-' + message_status"
                role="alert"
                v-if="message"
              >
                {{ message }}
              </div>
              <form role="form" class="text-start">
                <div class="input-group input-group-outline py-2 my-4">
                  <label class="form-label my-2"
                    ><img
                      class="form-label-icon"
                      :src="require(`@/assets/img/icons/username.png`)"
                  /></label>
                  <input
                    type="text"
                    class="form-control py-3"
                    placeholder="Firstname"
                    v-model="first_name"
                  />
                </div>
                <div class="input-group input-group-outline py-2 my-4">
                  <label class="form-label my-2"
                    ><img
                      class="form-label-icon"
                      :src="require(`@/assets/img/icons/username.png`)"
                  /></label>
                  <input
                    type="text"
                    class="form-control py-3"
                    placeholder="Lastname"
                    v-model="last_name"
                  />
                </div>
                <div class="text-center mt-3">
                  <button
                    type="button"
                    class="btn bg-default text-white w-50 my-4 mb-2 py-3"
                    v-on:click="changeProfile"
                  >
                    Modifier
                  </button>
                </div>
                <p class="mt-4 text-sm text-center"></p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import request from "../../utils/request";
import localStorageManager from "../../utils/localStorageManager";

export default {
  name: "Profile",
  data() {
    return {
      id: null,
      first_name: "",
      last_name: "",
      message: "",
    };
  },
  mounted() {
    this.profile();
  },
  methods: {
    async profile() {
      const response = await request.get("/user/profile", {});
      if (response.status === 200) {
        let data = response.data.data;

        this.first_name = data.first_name;
        this.last_name = data.last_name;
      } else {
      }
    },
    redirect() {
      this.$router.push("/");
    },
    async changeProfile(e) {
      $(e.target).prop("disabled", true);
      e.preventDefault();

      const response = await request.put(
        "/user/profile",
        {
          first_name: this.first_name,
          last_name: this.last_name,
        },
        {}
      );

      let data = response.data;
      this.message = data.message;
      if (response.status === 200) {
        this.message_status = "success";
        localStorageManager.setItem("first_name", this.first_name);
        localStorageManager.setItem("last_name", this.last_name);
        setTimeout(
          function () {
            this.redirect();
          }.bind(this),
          2000
        );
      } else if (response.status === 400) {
        this.message_status = "warning";
      } else {
        this.message_status = "danger";
      }

      $(e.target).prop("disabled", false);
    },
  },
};
</script>

<style scoped></style>
